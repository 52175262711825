import React from 'react';
import { Form, Label, Button, Input, Row, Col} from 'reactstrap';
import "./search.styles.scss";

const Search = ({placeholder, submitSearch, handleSearchChange}) => {

    const handleChange = e => {
        e.preventDefault();
        const { value } = e.target;
        const terms = value.replace(/[/]/g, '_');

        if(value === ""){
            handleSearchChange(0);
        }else{
            handleSearchChange(terms);
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        submitSearch()
    }

    return(
        <div className="search-bar">
            <Form onSubmit={handleSubmit}>
            <Row form>
                <Col md={6} className="search-input">
                    <Label for="search">Search</Label>
                        <Input
                        type="search"
                        name="search"
                        id="search"
                        placeholder={placeholder}
                        onChange={handleChange}
                        />
                </Col>
                <Col className="search-button" md={2}>
                    <Button color="primary">Search</Button>
                </Col>
            </Row>
            </Form>
        </div>
    );
}

export default Search;
