const validateEntries = entries => {
    if(!entries.advertiser){
        alert("You have not selected an advertiser. Please select an advertiser and re-submit.")
        return false;
    }else if(!entries.salesRep){
        alert("You have not selected a Sales Representative. Please select a Sales Representative and re-submit.")
        return false;
    }else if(invalidOrder(entries.order)){
        return false;
    }else if(invalidPrice(entries.price)){
        return false;
    }else if(entries.invalidTotal){
        var answer = window.confirm("The total Sale Price does not appear to equal to the sum of all invoices. Are you sure you want to submit?")
        return answer;
    }else {
        return true;
    }
}

export default validateEntries;

//helper Functions

const invalidOrder = orders => {
    let invalid = false;

    if(orders.length === 0){
        alert("You have not entered any Order Details. Please fill out Order Details and Re-Submit")
        invalid = true;
    }

    orders.forEach((order, index) => {
        if(!order.type){
            alert("Purchase #" + (index+1) + " does not have a selected Ad type. Please fill out all Order Details and Re-Submit ")
            invalid = true;
        }else if(!order.size || order.size === "none"){
            alert("Purchase #" + (index+1) + " does not have a selected Ad Size. Please fill out all Order Details and Re-Submit ")
            invalid = true;
        }else if(!order.run_on){
            alert("Purchase #" + (index+1) + " does not have any selected Issues or run time. Please fill out all Order Details and Re-Submit ")
            invalid = true;
        }else if(!order.run_until && (order.type !== "print" || order.frequency === "multiple-by-time")){
            alert("Purchase #" + (index+1) + " does not have a selected run time end date. Please fill out all Order Details and Re-Submit ")
            invalid = true;
        }else if(order.frequency === "multiple-by-selection"){
            order.run_on.forEach(date => {
                if(date === 0 || date === ""){
                    alert("Purchase #" + (index+1) + " has issues added without selected issue dates. Please fill out all Order Details and Re-Submit ")
                    invalid = true;
                }
            });
        }
    });
    return invalid;
}

const invalidPrice = price => {
    let invalid = false;

    price.forEach((sale, index) => {
        if(sale === 0 || sale === "0" || sale ==="0.00"){
            var answer = false;
            if(index === 0){
                answer = window.confirm("The total Sale Price is '$0.00'. Are you sure you want to submit?")
            }else{
                answer = window.confirm("The Sale Price for Invoice #" + index +" is '$0.00'. Are you sure you want to submit?")
            }
            invalid = !answer;
        }
    });
    return invalid;
}