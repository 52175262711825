import React, { useState, useEffect } from 'react';
import Crumbs from './../crumbs.component';
import { Spinner } from 'reactstrap';
import withAuthProvider from './../../AuthProvider';
import { uploadFileToSharepoint, getItemDetails } from './../../GraphService';
import { config } from '../../Config';
import Ad from './ad.component';
import DeleteAdModal from './deleteAd.modal';
import AddNote from './../notes/AddNote.modal';
import { withRouter } from 'react-router-dom';
import UploadFileModal from './../UploadFile';
import UploadModal from './../upload.modal';
import UpdateRunTime from './updateRunTime.component';
import './ad.styles.scss'

const AdContainer = ({ adId, getAccessToken, history, asPage, refreshSalePage, axios }) => {

    const [ad, setAd] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [noteModal, setNoteModal] = useState(false);
    const [datesModal, setDatesModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);

    //update info from api everytime page loads
    useEffect(() => {
        //populate ad state
        axios.get(`/api/ads/${adId}`)
        .then(res => {
            setAd(res.data);
            getFiles(res.data.files);
        })
        
    }, [adId]) // eslint-disable-line

    const getFiles = async (files) => {
        const filesPromises = files.map(async file => {
            const token = await getAccessToken(config.scopes);
            const details = await getItemDetails(token, file);
            return details;
        });
        Promise.all(filesPromises)
        .then(filesArray => {
            //filter out where null
            setFiles(filesArray.filter(file => file !== null));
        })
        .catch(e => {
            console.error(e);
        })
    }

    const uploadFile = async (file) => {
        setUploading(file.name);
        const token = await getAccessToken(config.scopes);
        const response = await uploadFileToSharepoint(token, file, ad.short_name, "Ad")
        setUploading(false);
        if(response){ 
            //axios update call
            axios.post(`/api/ads/${adId}`, {file: response})
            .then(res => {
                //update file list on frontend
                getFiles(res.data.files)
                alert(file.name + " Successfully Uploaded to Sharepoint.")
            })
        }
    }

    const updateDates = updates => {
        if (!updates.isEmpty){
            //axios update call
            axios.post(`/api/ads/${adId}/changeDates`, updates)
            .then(res => {
                alert("Dates for this Ad Successfully Updated.")
                setAd(res.data);
            })
        }else{
            alert("No changes have been entered.")
        }
    }

    const updateAd = updates => {
        if (!updates.isEmpty){
            //axios update call
            axios.post(`/api/ads/${adId}`, updates)
            .then(res => {
                alert("Ad Successfully Updated.")
                setAd(res.data);
            })
        }else{
            alert("No changes have been entered.")
        }
    }

    const removeAd = () => {
        //axios delete call
        axios.delete(`/api/ads/${adId}`)
        .then(res => {
            if(asPage){
                alert("This Ad has been deleted. You will be redirected to this ad's sale.")
                history.push(`/app/sales/manage/${ad.sale}`)
            }else{
                alert("This Ad has been deleted.")
                refreshSalePage();
            }
        })
    }

    const submitNote = note => {
        if (note.note !== ""){
            //axios update call
            axios.post(`/api/ads/${adId}/newNote`, note)
            .then(res => {
                setAd(res.data);
            }).catch(err => alert(`Something went wrong: ${err.message}`))
        }else{
            alert("The note you attempted to submit is empty");
        }
    }

    if(ad){
        return(
            <div>
            {asPage ? <Crumbs prevPaths={[['/app/ads', 'Ads']]} page={ad.short_name} /> : null}
                <div className="page-content-wrapping">
                    <Ad 
                        ad={ad}
                        files={files}
                        uploadFile={() => setUploadModal(true)} 
                        updateAd={(updates) => updateAd(updates)}
                        updateDates={() => setDatesModal(true)}
                        removeAd={() => setDeleteModal(true)}
                        addNote={() => setNoteModal(true)}
                    />
                    {deleteModal ? <DeleteAdModal 
                        name={ad.short_name}
                        removeAd={() => removeAd()}
                        cancelDelete={() => setDeleteModal(false)}
                    /> : null}
                    {noteModal ? <AddNote
                        submitNote={(note) => submitNote(note)}
                        close={() => setNoteModal(false)}
                    /> : null}
                    {datesModal ?  <UpdateRunTime
                        type={ad.type}
                        closeModal={() => setDatesModal(false)}
                        submitUpdates={(updates) => updateDates(updates)}
                    /> : null}
                    {uploading ? 
                        <UploadModal filename={uploading}/>  : 
                    uploadModal ? 
                        <UploadFileModal
                            uploadFile={(file) => uploadFile(file)}
                            closeModal={() => setUploadModal(false)}
                        /> 
                    : null}
                </div>
            </div>
        );  
    }else{
        return <Spinner/>
    }
}

export default withRouter(withAuthProvider(AdContainer));