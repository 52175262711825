import React from 'react';
import { Label, Button, Input, Row, Col} from 'reactstrap';

const InvoiceDateFilter = ({handleDateChange, query, clearFilter}) => {

    const handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        handleDateChange({[name]: value});
    }

    return(
        <div style={{marginBottom: '20px'}}>
            <Label>Filter by Dates</Label>
            <Row form>
            <Col md={3}>
                <Label for="dateType">Choose Event Type</Label>
                <Input onChange={handleChange} type="select" name="dateType" value={query.dateType}>
                    <option value="date">Any</option>
                    <option value="sendDate">To Send Date</option>
                    <option value="sentDate">Sent On Date</option>
                    <option value="paidDate">Paid On Date</option>
                </Input>           
            </Col>
            <Col md={3}>
                <Label for="date1">Start Date</Label>
                    <Input
                    type="date"
                    name="date1"
                    id="date1"
                    value={query.date1}
                    onChange={handleChange}
                    />
            </Col>
            <Col md={3}>
                <Label for="date2">End Date</Label>
                    <Input
                    type="date"
                    name="date2"
                    id="date2"
                    value={query.date2}
                    onChange={handleChange}
                    />
            </Col>
            <Col style={{display: "flex", alignItems: "flex-end"}}>
                <Button onClick={() => clearFilter()}>Clear Filter</Button>
            </Col>
            </Row>
        </div>
    );
}

export default InvoiceDateFilter;
