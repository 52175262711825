import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Updates from './../../components/Updates.component'
import './dashboard.styles.scss';

const Dashboard = ({ history }) => {
    return(
        <div className="dashboard">
            <div className="updates-board">
                <h6>Updates</h6>
                <Updates/>
            </div>
            <div className="quick-nav">
                <h6>Quick Actions</h6>
                    <Button onClick={() => history.push('app/sales/add')}>Enter New Sale</Button>
                    <Button onClick={() => history.push('app/ads/nextissue')}>View Ads for Next Issue</Button>
                    <Button onClick={() => history.push('dedicated-emails-calendar')}>Dedicated Emails Calendar</Button>
                <hr/><h6>Pages</h6>
                    <Button onClick={() => history.push('app/sales')}>Sales</Button>
                    <Button onClick={() => history.push('app/invoices')}>Invoices</Button>
                    <Button onClick={() => history.push('app/ads')}>Ads</Button>
                    <Button onClick={() => history.push('app/advertisers')}>Advertisers</Button>
                <hr/><h6>Other</h6>
                    <Button onClick={() => history.push('app/reports')}>Run Reports</Button>
                    <Button onClick={() => history.push('app/settings')}>Settings</Button>
            </div>
        </div>
    );
}

export default withRouter(Dashboard);