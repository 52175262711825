import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Progress } from 'reactstrap';

const UploadModal = (props) => {
  const {
    filename
  } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal isOpen={modal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
        toggle={toggle}>
        <ModalBody>
        Uploading {filename} to SharePoint... This may take a few minutes.
        </ModalBody>
        <Progress animated value="100" />
        <ModalFooter>
          <Button color="danger" onClick={toggle}>Cancel Upload</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default UploadModal;