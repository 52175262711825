import React, {useState, useEffect} from 'react';
import { ListGroup, ListGroupItem, Spinner, 
    Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import withAuthProvider from './../../AuthProvider';

const AdvertisersList = ({ history, search, axios }) => {
    
    const [advertisers, setAdvertisers] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);

    //update info from api on mount
    useEffect(() => {
        axios.get(`/api/advertisers/list/1/${search}`)
            .then(res => {
                const advertisersData = res.data.list.map(({company_name, id}) => {
                    return {company_name, id}    
                })
                const total = parseInt(res.data.pageCount);
                setPages(buildNav(total, 1));
                setPageCount(total);
                setAdvertisers(advertisersData);
                setIsFetching(false);
                setCurrentPage(1);
            })
    }, [search]); // eslint-disable-line

    const buildNav = (pageCount, page) => {
        const nav = [];

        if(page > 3){
            nav[0] = page-2;
            nav[1] = page-1;
            nav[2] = page;
            if (page+1 <= pageCount){nav[3] = page+1;}
            if (page+2 <= pageCount){nav[4] = page+2;}
        }else{
            nav[0] = 1;
            if (2 <= pageCount){nav[1] = 2;}
            if (3 <= pageCount){nav[2] = 3;}
            if (4 <= pageCount){nav[3] = 4;}
            if (5 <= pageCount){nav[4] = 5;}
        }
    return nav;
    }

    const handlePageChange = page => {
        setIsFetching(true);
        axios.get(`/api/advertisers/list/${page}/${search}`)
            .then(res => {
                const advertisersData = res.data.list.map(({company_name, id}) => {
                    return {company_name, id}    
                })
                setPages(buildNav(pageCount, page));
                setAdvertisers(advertisersData);
                setIsFetching(false);
                setCurrentPage(page);
            })
    }

    return(
        <div>
            <div className="advertisers-list-container">
                <ListGroup className="advertisers-list">
                { isFetching ? <Spinner/> : advertisers.length < 1 ? <p>No Results Found.</p> : null}
                {advertisers.map(advertiser => {
                    return(
                        <ListGroupItem 
                        action
                        onClick={() => history.push(`/app/advertisers/manage/${advertiser.id}`)}   
                        tag="button" 
                        key={advertiser.id}
                        >
                            <div className="advertiser-list-item">
                                <span>{advertiser.company_name}</span>
                            </div>
                        </ListGroupItem>
                    );
                })}
                </ListGroup>
            </div>
            <Pagination aria-label="advertiser Page Navigation">
            <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink first onClick={() => handlePageChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {
                  pages.map(page => {
                      return(
                          <PaginationItem key={page} active={page === currentPage ? true : undefined}>
                              <PaginationLink 
                              onClick={() => handlePageChange(page)}
                              >
                              {page}
                              </PaginationLink>
                          </PaginationItem>
                      );
                  })
              }
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink last onClick={() => handlePageChange(pageCount)} />
              </PaginationItem>
            </Pagination>
        </div>
    );
}

export default withAuthProvider(withRouter(AdvertisersList));