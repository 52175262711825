import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const DeleteAdModal = (props) => {
  const {
    name,
    removeAd,
    cancelDelete
  } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => {
      setModal(!modal);
      cancelDelete();
  }

  const confirm = () => {
    setModal(!modal);
    removeAd();
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
        <p>You are about to permanently delete this ad ({name}). This action cannot be reversed.</p> 
        <b>Are you sure you want to delete this ad?</b>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggle}>Cancel</Button>
            <Button color="danger" onClick={() => confirm()}>Yes I'm Sure</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteAdModal;