import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, Label, CustomInput, ModalHeader } from 'reactstrap';

const UploadFileModal = ({uploadFile, closeModal}) => {

    const [modal, setModal] = useState(true);
    const [file, setFile] = useState();

    const toggle = () => {
        setModal(!modal);
        closeModal()
    }

    const handleUpload = () => {
        if(file){
            uploadFile(file);
            setModal(!modal);
            closeModal()
        }else{
            alert("No file selected for upload.")
        }
    }

    const handleFileChange = e => {
        const {files} = e.target;
        setFile(files[0]);
    }

  return (
    <div>
      <Modal className="form-modal" isOpen={modal} toggle={toggle}>
        <ModalHeader>
        Upload File 
        </ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label for="File">Select File</Label>
                <CustomInput onChange={handleFileChange} type="file" id="File" name="File" />
            </FormGroup>
        </ModalBody>
        
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={() => handleUpload()}>Upload</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default UploadFileModal;