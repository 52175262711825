import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const InvalidSearchModal = ({message, clearFilter, cancelSubmit}) => {
 
  const toggle = () => cancelSubmit();

  return (
    <div>
      <Modal isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>Incomplete Search Fields</ModalHeader>
        <ModalBody>
        {message}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => cancelSubmit()}>Cancel</Button>
          <Button color="primary" onClick={() => clearFilter()}>Clear Filter and Search</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default InvalidSearchModal;