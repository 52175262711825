import React from 'react';
import InvoiceContainer from './../../../components/invoice/invoice.container';

const ManageInvoicePage = ({ match }) => {

    //get id of invoice
    const invoiceId = match.params.invoiceId;

    return(<InvoiceContainer invoiceId={invoiceId} asPage />);  
}

export default ManageInvoicePage;