import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const Crumbs = ({ history, prevPaths, page }) => {
    const paths = []

    if(prevPaths){
        prevPaths.forEach(path => {
            paths.push(
                <BreadcrumbItem key={path[1]} tag="a" onClick={() => history.push(`${path[0]}`)}>
                    {path[1]}
                </BreadcrumbItem>
            );
        });
    }
   
    return(
        <div>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag="a" onClick={() => history.push('/app')}>Home</BreadcrumbItem>
                {paths}
                <BreadcrumbItem active tag="span">{page}</BreadcrumbItem>
            </Breadcrumb>
        </div>
    );
}

export default withRouter(Crumbs);