import React, {useState, useEffect} from 'react';
import { ListGroup, ListGroupItem, Badge, Button, Nav, NavLink, NavItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import UpdateAdForm from './updateAd.component';
import ShowNotes from './../notes/ShowNotes.component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectSizes } from './../../redux/data/data.selectors';
import { refreshData } from './../../redux/data/data.actions';
import withAuthProvider from './../../AuthProvider';
import './ad.styles.scss';


const Ad = ({ ad, history, updateAd, updateDates, removeAd, uploadFile, 
    files, sizes, checkForRefresh, addNote, axios, getAccessToken }) => {

    useEffect(() => {
        const initPage = async () => {
            const token = await getAccessToken(['api://9523c67c-63d0-4a6a-8354-6750e64abaa3/TLC.Read']);
            const verify = {
                headers: { 'access-token': token }
            }
            checkForRefresh(verify);
            axios.get('/api/issues/names')
            .then(res => setIssueNames(res.data))
        }
        initPage();
    },[checkForRefresh]); // eslint-disable-line

    const [updates, setUpdates] = useState({isEmpty: true});
    const [issueNames, setIssueNames] = useState({});
    const [updateModal, setUpdateModal] = useState(false);

    const {
        run_on, run_status, premium, type, size, advertiser, sale, notes, 
        short_name, company_name, run_until 
    } = ad;

    const handleUpdateSubmit = () => {
        if(updates.size){
            const short_name = updates.size + " - " + company_name;
            updateAd({...updates, short_name });
            setUpdates({isEmpty: true});
        }else{
            updateAd(updates);
            setUpdates({isEmpty: true});
        }
    }

    const getRunTime = () => {
        const start = new Date(run_on[0]);
        const end = new Date(run_until);
        return(start.toDateString() + " - " + end.toDateString())
    }

    const stageUpdate = update => {
        setUpdates({...updates, ...update, isEmpty: false})
    }

    return(
       <div className="single-ad">

            {updateModal ? 
                <UpdateAdForm
                    sizes={sizes}
                    order={ad}
                    setOrder={(update) => stageUpdate(update)}
                    submitUpdate={() => handleUpdateSubmit()}
                    closeModal={() => setUpdateModal(false)}
                /> 
            : null}

            <div className="ad-actions">
                <Nav>
                    <NavItem>
                        <NavLink onClick={() => addNote()}>New Note</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setUpdateModal(true)}>Update Ad</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => uploadFile()}>Upload File</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => updateDates()}>Update Run Time</NavLink>
                    </NavItem> 
                </Nav>
                <Nav className="ad-actions">
                    <NavItem>
                        <NavLink className="delete-button" onClick={() => removeAd()}>Delete This Ad</NavLink>
                    </NavItem>
                </Nav>
            </div>
            <hr style={{backgroundColor: "#aa6305"}}/>
            
            <div className="single-ad-title">
                <h6 style={{padding: "0", color: "#aa6305"}}>Showing Ad</h6>
                <h3>{short_name}</h3>
            </div>
                     
            <div className="ad-info">
                <div className="basic-ad-info">
                    <h6>Ad Details</h6>
                    <p>Ad Type: <b>{type}</b></p>
                    <p>Ad Size: <b>{size}</b></p>
                    <p>Premium Placement: {premium ? <b>yes</b> : <b>no</b>}</p>
                </div>
                <div className="basic-ad-info">
                    <h6>Run Details</h6>
                    <p>Run Status: <b>{run_status}</b></p>
                    {run_until ?
                        <p>Run Time: <b>{getRunTime()}</b></p>
                    :
                    <div>
                    <hr/>
                        <h6>Issues</h6>
                        <ul>
                        {run_on.map(issue => {
                            let date = new Date(issue);
                            return <li key={issue}>{
                                issueNames[date.toDateString()] ?
                                issueNames[date.toDateString()] :
                                date.toDateString()
                            }</li>
                        })}
                        </ul>
                    </div>
                    } 
                </div>
                <div className="basic-ad-info">
                    <h6>Quick Links</h6>
                    <NavLink style={{textAlign: 'center'}} onClick={() => {history.push(`/app/advertisers/manage/${advertiser}`)}}>Go To {company_name}</NavLink>
                    <NavLink style={{textAlign: 'center'}} onClick={() => {history.push(`/app/sales/manage/${sale}`)}}>Go To Sale</NavLink>
                </div>
            </div>

            <div className="ad-content">
                <div className="ad-content-left">
                    <h6>Notes</h6>
                    <ShowNotes notes={notes}/>
                    <Button className="add-note" size="sm" color="primary" onClick={() => addNote()}>+</Button>
                </div>
                <div className="ad-content-right">
                    <h6>Files</h6>
                    <ListGroup>
                    {files.map(file => {
                        return(
                            <ListGroupItem key={file.name} className="file-list-item">
                                <span>
                                    {file.name} <Badge pill>{file.size}</Badge>
                                </span>
                                <span>
                                    <Button size="sm" className="file-buttons" target="_blank" href={file.viewUrl}>View File</Button>
                                    <Button size="sm" className="file-buttons" href={file.downloadUrl}>Download File</Button>
                                </span>
                            </ListGroupItem>
                        ); 
                    })}
                    </ListGroup>
                    <Button className="add-note" size="sm" onClick={() => uploadFile()}>+</Button>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    checkForRefresh: (verify) => dispatch(refreshData(verify))
});

const mapStateToProps = createStructuredSelector({
    sizes: selectSizes
});

export default withAuthProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(Ad)));

