export const config = {
    appId: "9523c67c-63d0-4a6a-8354-6750e64abaa3",
    authority: "https://login.microsoftonline.com/2f45fb89-e929-4257-877d-9c7f826d0a12/",
    redirectUri: "https://ads.livingchurch.org",
    //redirectUri: "http://localhost:3000",
    scopes: [
      'user.read',
      'mailboxsettings.read',
      'Mail.Send',
      'Files.ReadWrite.All',
      'Sites.ReadWrite.All'
    ]
};