const dataActionTypes = {
    RETRIEVE_REPS_START: 'RETRIEVE_REPS_START',
    RETRIEVE_REPS_SUCCESS: 'RETRIEVE_REPS_SUCCESS',
    RETRIEVE_REPS_FAILURE: 'RETRIEVE_REPS_FAILURE',
    RETRIEVE_SIZES_START: 'RETRIEVE_SIZES_START',
    RETRIEVE_SIZES_SUCCESS: 'RETRIEVE_SIZES_SUCCESS',
    RETRIEVE_SIZES_FAILURE: 'RETRIEVE_SIZES_FAILURE',
    RETRIEVE_ADVERTISERS_START: 'RETRIEVE_ADVERTISERS_START',
    RETRIEVE_ADVERTISERS_SUCCESS: 'RETRIEVE_ADVERTISERS_SUCCESS',
    RETRIEVE_ADVERTISERS_FAILURE: 'RETRIEVE_ADVERTISERS_FAILURE',
    
};

export default dataActionTypes;