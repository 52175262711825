import { createSelector } from 'reselect';

const selectData = state => state.data;

export const selectReps = createSelector(
    [selectData],
    (data) => data.salesReps
);

export const selectAdvertisers = createSelector(
    [selectData],
    (data) => data.advertisers
);

export const selectSizes = createSelector(
    [selectData],
    (data) => data.sizes
);
