import React, {useState, useEffect} from 'react';
import { ListGroup, ListGroupItem, Spinner, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Search from './../search/Search.component';
import withAuthProvider from './../../AuthProvider';
import './SalesList.styles.scss';

const SalesList = ({ history, match, axios }) => {
    
    const [sales, setSales] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);
    const [searchInput, setSearchInput] = useState(0);
    const [search, setSearch] = useState(0);

    //update info from api on mount
    useEffect(() => {

        let page = 1;

        if(match.params.page){
            page = parseInt(match.params.page);
        }

        axios.get(`/api/sales/list/${page}/${search}`)
            .then(res => {
                const salesData = res.data.sales.map(({short_name, _id, sale_added}) => {
                    return {
                        name: short_name, 
                        id: _id, 
                        added: new Date(sale_added)
                    }    
                })
                const total = parseInt(res.data.pages);
                setPages(buildNav(total, page));
                setPageCount(total);
                setCurrentPage(page);
                setSales(salesData);
                setIsFetching(false);
            })
    }, [match.params.page, search]); // eslint-disable-line

    const buildNav = (pageCount, page) => {
        const nav = [];

        if(page > 3){
            nav[0] = page-2;
            nav[1] = page-1;
            nav[2] = page;
            if (page+1 <= pageCount){nav[3] = page+1;}
            if (page+2 <= pageCount){nav[4] = page+2;}
        }else{
            nav[0] = 1;
            if (2 <= pageCount){nav[1] = 2;}
            if (3 <= pageCount){nav[2] = 3;}
            if (4 <= pageCount){nav[3] = 4;}
            if (5 <= pageCount){nav[4] = 5;}
        }
    return nav;
    }

    const goToSale = id => {
        history.push(`/app/sales/manage/${id}`);
    }

    const handleSearchChange = input => {
        setSearchInput(input);
    }

    const handleSubmit = () => {
        setSearch(searchInput);
        history.push("/app/sales");
    }

    return(
        <div>
            <div className="search-bar">
                <Search 
                placeholder="Search All Sales"
                submitSearch={() => handleSubmit()}
                handleSearchChange={(input) => handleSearchChange(input)}
                />
            </div>

            <div className="sale-list-container">
                <ListGroup className="sales-list">
                { isFetching ? <Spinner/> : sales.length < 1 ? <p>No Results Found.</p> : null}
                {
                    sales.map(({name, added, id}) => {
                        return(
                            <ListGroupItem
                                action
                                className="sales-list-item"
                                tag="button"
                                onClick={() => goToSale(id)}
                                key={id}
                            >
                            <span>{name}</span> <span><sub>Added on </sub><span className="added-on-date">{added.toDateString()}</span></span>
                            </ListGroupItem>
                        );
                    })
                     
                }
                </ListGroup>
            </div>
            <Pagination size="md" aria-label="Sales Page Navigation">
            <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink first onClick={() => history.push("/app/sales")} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink previous onClick={() => history.push(`/app/sales/page/${currentPage-1}`)} />
              </PaginationItem>
              {
                  pages.map(page => {
                      return(
                          <PaginationItem key={page} active={page === currentPage ? true : undefined}>
                              <PaginationLink 
                              onClick={() => history.push(`/app/sales/page/${page}`)}>
                              {page}
                              </PaginationLink>
                          </PaginationItem>
                      );
                  })
              }
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink next onClick={ () => history.push(`/app/sales/page/${currentPage+1}`)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink last onClick={() => history.push(`/app/sales/page/${pageCount}`)} />
              </PaginationItem>
            </Pagination>
        </div>
    );
}

export default withAuthProvider(withRouter(SalesList));

