import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Fade, Button, Collapse } from 'reactstrap';
import classnames from 'classnames';
import Crumbs from './../../components/crumbs.component';
import Search from './../../components/search/Search.component';
import InvalidSearchModal from './../../components/invalid-search/InvalidSearch.modal';
import InvoiceDateFilter from './../../components/invoicesList/InvoiceDateFilter.component';
import InvoicesList from './../../components/invoicesList/invoicesList.component';
import './invoices.page.styles.scss';

const InvoicesPage = ({sent, overdue, send, sendsoon}) => {

    const [activeTab, setActiveTab] = useState('all');
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const [dates, setDates] = useState({dateType: "date", date1: 0, date2: 0});
    const [search, setSearch] = useState(0);
    const [submission, setSubmission] = useState({search: 0, dateType: 0, date1: 0, date2: 0});

    //prefill search query for update alert cases
    useEffect(() => {
        if(sent){
            setActiveTab("sent");
        }else if(overdue){
            setActiveTab("sent");
            const d = new Date()
            d.setMonth(d.getMonth() - 1);
            const monthAgo = d.getFullYear() + "-" + 
                ("0" + (d.getMonth() +1) ).slice(-2) + "-" + 
                ("0" + d.getDate()).slice(-2); 
            setDates({dateType: "sentDate", date1: "2000-01-01", date2: monthAgo});
            setSubmission({...submission, dateType: "sentDate", date1: "2000-01-01", date2: monthAgo})
        }else if(send){
            setActiveTab("send");
        }else if(sendsoon){
            setActiveTab("future");
            const d = new Date()
            d.setDate(d.getDate() + 7);
            const nextWeek = d.getFullYear() + "-" + 
                ("0" + (d.getMonth() +1) ).slice(-2) + "-" + 
                ("0" + d.getDate()).slice(-2); 
            setDates({dateType: "sendDate", date1: "2000-01-01", date2: nextWeek});
            setSubmission({...submission, dateType: "sendDate", date1: "2000-01-01", date2: nextWeek})
        }
    }, [sent, overdue, send, sendsoon]) //eslint-disable-line

    const handleSubmit = () => {
        if(dates.date1 === 0 && dates.date2 !== 0){
            setModalMessage("No start date was specified. Please select a start date or clear the date filter.")
            setOpenErrorModal(true);
        }else if(dates.date2 === 0 && dates.date1 !== 0){
            setModalMessage("No end date was specified. Please select an end date or clear the date filter.")
            setOpenErrorModal(true);
        }else if(dates.date1 === 0 && dates.date2 === 0){
            setSubmission({...submission, search: search});
        }else{
            setSubmission({...dates, search: search})
        }
    }

    const cancelSubmit = () => {
        setOpenErrorModal(false);
    }

    const clearFilter = () => {
        setDates({dateType: "date", date1: 0, date2: 0})
        setSubmission({dateType: 0, date1: 0, date2: 0, search: search})
        setOpenErrorModal(false);
    }

    const handleDateChange = newDates => {
        setDates({...dates, ...newDates});
    }

    const handleSearchChange = input => {
        setSearch(input);
    }

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const [fadeIn, setFadeIn] = useState(false);
    const toggleFade = () => setFadeIn(!fadeIn);

    return(
        <div>
        {openErrorModal ? <InvalidSearchModal message={modalMessage} clearFilter={() => clearFilter()} cancelSubmit={() => cancelSubmit()} /> : null}
            <Crumbs page="Invoices"/>
            <div className="invoices-page">

            <h6 style={{padding: "0", color: "#1e7e34"}}>Invoices</h6>
            <hr style={{backgroundColor: "#1e7e34"}}/>

            <div className="invoice-search-filter">
                
                <Search 
                    placeholder="Search Invoices by Name or Invoice Number"
                    submitSearch={() => handleSubmit()}
                    handleSearchChange={(input) => handleSearchChange(input)}
                />

                <div>
                    <Button className="advanced-search-button" size="sm" onClick={toggleFade}>
                        { fadeIn ? <span>Advanced Search  &#9660; </span>: <span>Advanced Search  &#9650;</span>}
                    </Button>
                    <Fade in={fadeIn}>
                        <Collapse isOpen={fadeIn}>
                            <InvoiceDateFilter
                            handleDateChange={(newDates) => handleDateChange(newDates)}
                            clearFilter={() => clearFilter()}
                            query={dates}
                            />
                        </Collapse>
                    </Fade>
                </div>
                <hr/>
            </div>

            <Nav tabs>
                <NavItem>
                    <NavLink 
                    className={classnames({ active: activeTab === 'all' })}
                    onClick={() => { toggle('all'); }}>All</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'send' })}
                    onClick={() => { toggle('send'); }}>Send</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'sent' })}
                    onClick={() => { toggle('sent'); }}>Sent</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'future' })}
                    onClick={() => { toggle('future'); }}>Upcoming</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'paid' })}
                    onClick={() => { toggle('paid'); }}>Paid</NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="all">
                    <InvoicesList search={submission} status="all"/>
                </TabPane>
                <TabPane tabId="send">
                    <InvoicesList search={submission} status="send"/>
                </TabPane>
                <TabPane tabId="sent">
                    <InvoicesList search={submission} status="sent"/>
                </TabPane>
                <TabPane tabId="future">
                    <InvoicesList search={submission} status="future"/>
                </TabPane>
                <TabPane tabId="paid">
                    <InvoicesList search={submission} status="paid"/>
                </TabPane>
            </TabContent>
            </div>
        </div>
    );
}

export default InvoicesPage;

