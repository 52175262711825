import React, { useState, useEffect } from 'react';
import withAuthProvider from './../../AuthProvider';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, FormGroup, 
    Label, Input, Fade, Collapse, Button } from 'reactstrap';
import classnames from 'classnames';
import Crumbs from './../../components/crumbs.component';
import Search from './../../components/search/Search.component';
import InvalidSearchModal from './../../components/invalid-search/InvalidSearch.modal';
import AdsList from './../../components/adsList/AdsList.component';
import AdDateFilter from './../../components/adsList/AdDateFilter.component';
import "./ads.page.styles.scss";

const AdsPage = ({nextIssue, noResource, dedicated, axios}) => {

    const [activeTab, setActiveTab] = useState('all');
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const [dates, setDates] = useState({dateType: "range", date1: 0, date2: 0});
    const [search, setSearch] = useState(0);
    const [adType, setAdType] = useState(0);
    const [fileStatus, setFileStatus] = useState(0);
    const [submission, setSubmission] = useState({search: 0, adType: 0, dateType: 0, date1: 0, date2: 0, hasFiles: 0});

    useEffect(() => {
        if(nextIssue){
            axios.get("/api/issues/next")
            .then(res => {
                setDates({dateType: "issue", date1: res.data, date2: 0})
                setAdType("print")
                if(noResource){
                    setFileStatus("no")
                    setSubmission({...submission, adType: "print", dateType: "issue", date1: res.data, hasFiles: "no"});
                }else{
                    setSubmission({...submission, adType: "print", dateType: "issue", date1: res.data});
                }
            })
        }
        if(dedicated){
            setAdType("other");
            
            const d = new Date()
            const today = d.getFullYear() + "-" + 
                ("0" + (d.getMonth() +1) ).slice(-2) + "-" + 
                ("0" + d.getDate()).slice(-2); 

            d.setDate(d.getDate() + 7);
            const nextWeek = d.getFullYear() + "-" + 
                ("0" + (d.getMonth() +1) ).slice(-2) + "-" + 
                ("0" + d.getDate()).slice(-2); 
            setDates({dateType: "range", date1: today, date2: nextWeek});
            setSubmission({...submission, adType: "other", dateType: "range", date1: today, date2: nextWeek})
        }
    }, [nextIssue]) // eslint-disable-line

    const handleSubmit = () => {
        if(dates.date1 === 0 && dates.date2 !== 0){
            setModalMessage("No start date was specified. Please select a start date or clear the date filter.")
            setOpenErrorModal(true);
        }else if(dates.date2 === 0 && dates.date1 !== 0 && dates.dateType === "range"){
            setModalMessage("No end date was specified. Please select an end date or clear the date filter.")
            setOpenErrorModal(true);
        }else if(dates.date2 === 0 && dates.date1 === 0 ){
            setSubmission({...submission, search: search, adType: adType, hasFiles: fileStatus});
        }else{
            setSubmission({...dates, search: search, adType: adType, hasFiles: fileStatus});
        }
    }

    const cancelSubmit = () => {
        setOpenErrorModal(false);
    }

    const clearFilter = () => {
        setDates({...dates, date1: 0, date2: 0})
        setSubmission({dateType: 0, date1: 0, date2: 0, search: search, adType: adType, hasFiles: fileStatus})
        setOpenErrorModal(false);
    }

    const handleDateChange = newDates => {
        setDates({...dates, ...newDates});
    }

    const handleFilesChange = e => {
        const { value } = e.target;
        setFileStatus(value);
    }

    const handleAdTypeChange = e => {
        const { value } = e.target;
        setAdType(value);

        //update dates in case dateType is issue
        if(dates.dateType === "issue"){
            setDates({date1: 0, date2: 0, dateType: "range"})
        }
    }

    const handleSearchChange = input => {
        setSearch(input);
    }

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const [fadeIn, setFadeIn] = useState(false);
    const toggleFade = () => setFadeIn(!fadeIn);

    return(
        <div>
        {openErrorModal ? <InvalidSearchModal message={modalMessage} clearFilter={() => clearFilter()} cancelSubmit={() => cancelSubmit()} /> : null}
            <Crumbs page="Ads"/>
            <div className="ads-page">

            <h6 style={{padding: "0", color: "#aa6305"}}>Ads</h6>
            <hr style={{backgroundColor: "#aa6305"}}/>

            <div className="ads-search-filter">
                <Search 
                    placeholder="Search Ads by Name or Size"
                    submitSearch={() => handleSubmit()}
                    handleSearchChange={(input) => handleSearchChange(input)}
                />

                <div>
                    <Button className="advanced-search-button" size="sm" onClick={toggleFade}>
                        { fadeIn ? <span>Advanced Search  &#9660; </span>: <span>Advanced Search  &#9650;</span>}
                    </Button>
                    <Fade in={fadeIn}>
                        <Collapse isOpen={fadeIn}>
                            <Row form>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label for="dateType">Choose Ad Type</Label>
                                        <Input onChange={handleAdTypeChange} type="select" name="adType" value={adType}>
                                            <option value={0}>Any</option>
                                            <option value="print">Print</option>
                                            <option value="web">Web</option>
                                            <option value="other">Other</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label for="fileStatus">Choose File Status</Label>
                                        <Input onChange={handleFilesChange} type="select" name="fileStatus" value={fileStatus}>
                                            <option value={0}>Any</option>
                                            <option value="yes">Contains File</option>
                                            <option value="no">No File</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr style={{margin: '0 0 5px 0'}}/>
                            <AdDateFilter 
                                handleDateChange={(newDates) => handleDateChange(newDates)}
                                clearFilter={() => clearFilter()}
                                query={dates}
                                adType={adType}
                            />
                        </Collapse>
                    </Fade>
                </div>
                <hr/>  
            </div>

            <Nav tabs>
                <NavItem>
                    <NavLink 
                    className={classnames({ active: activeTab === 'all' })}
                    onClick={() => { toggle('all'); }}>All</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'ongoing' })}
                    onClick={() => { toggle('ongoing'); }}>Ongoing</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'upcoming' })}
                    onClick={() => { toggle('upcoming'); }}>Upcoming</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'complete' })}
                    onClick={() => { toggle('complete'); }}>Complete</NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="all">                
                    <AdsList status="all" query={submission}/>
                </TabPane>
                <TabPane tabId="ongoing">                
                    <AdsList status="ongoing" query={submission}/>
                </TabPane>
                <TabPane tabId="upcoming">                
                    <AdsList status="upcoming" query={submission}/>
                </TabPane>
                <TabPane tabId="complete">                
                    <AdsList status="complete" query={submission}/>
                </TabPane>
            </TabContent>
        </div>
        </div>
    );
}

export default withAuthProvider(AdsPage);

