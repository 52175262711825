import React from 'react';
import Crumbs from '../../../components/crumbs.component';
import NewAdvertiserFormContainer from '../../../components/newAdvertiserForm/NewAdvertiserForm.container';

const NewAdvertiserPage = () => {
    return(
        <div>
            <Crumbs prevPaths={[['/app/advertisers', 'Advertisers']]} page="New Advertiser"/>
            <div className="new-sale-form">
                <h1>Enter New Advertiser</h1>
                <NewAdvertiserFormContainer/>
            </div>
        </div>    
    );
}

export default NewAdvertiserPage;