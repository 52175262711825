import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import Crumbs from '../crumbs.component';
import withAuthProvider from '../../AuthProvider';
import { uploadFileToSharepoint, getItemDetails } from '../../GraphService';
import { config } from '../../Config';
import Invoice from './Invoice.component';
import AddNote from './../notes/AddNote.modal';
import DeleteInvoiceModal from './deleteInvoice.Modal';
import UploadModal from './../upload.modal';
import UploadFileModal from './../UploadFile';
import { withRouter } from 'react-router-dom';
import './invoice.styles.scss';

const InvoiceContainer = ({ invoiceId, getAccessToken, asPage, history, refreshSalePage, axios }) => {

    const [invoice, setInvoice] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploadInvoice, setUploadInvoice] = useState(false);
    const [noteModal, setNoteModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);

    //update info from api everytime page loinvoices
    useEffect(() => {
        //populate invoice state
        axios.get(`/api/invoices/${invoiceId}`)
        .then(res => {
            setInvoice(res.data);
            getFiles(res.data.files);
        })
        
    }, [invoiceId]) // eslint-disable-line

    const getFiles = async (files) => {
        const filesPromises = files.map(async file => {
            const token = await getAccessToken(config.scopes);
            const details = await getItemDetails(token, file);
            return details;
        });
        Promise.all(filesPromises)
        .then(filesArray => {
            //filter out where null
            setFiles(filesArray.filter(file => file !== null));
        })
        .catch(e => {
            console.error(e);
        })
    }

    const uploadInvoiceFile = async (file) => {
        setUploadInvoice(file.name);
        const token = await getAccessToken(config.scopes);
        const response = await uploadFileToSharepoint(token, file, invoice.company_name, "Invoice")
        setUploadInvoice(false);
        if(response){ 
            
            //axios update call
            axios.post(`/api/invoices/${invoiceId}`, {file: response})
            .then(res => {
                //update file list on frontend
                getFiles(res.data.files)
                alert(file.name + " Successfully uploaded to Sharepoint.")
            })
        }
    }

    const updateInvoice = updates => {
        if (!updates.isEmpty){
            //axios update call
            axios.post(`/api/invoices/${invoiceId}`, updates)
            .then(res => {
                alert("invoice Successfully Updated.")
                setInvoice({...invoice, ...res.data});
            }).catch(err => alert(`Something went wrong: ${err.message}`))
        }else{
            alert("No changes have been entered.")
        }
    }

    const submitNote = note => {
        if (note.note !== ""){
            //axios update call
            axios.post(`/api/invoices/${invoiceId}/newNote`, note)
            .then(res => {
                setInvoice({...invoice, ...res.data});
            }).catch(err => alert(`Something went wrong: ${err.message}`))
        }else{
            alert("The note you attempted to submit is empty");
        }
    }

    const removeInvoice = () => {
        //axios delete call
        axios.delete(`/api/invoices/${invoiceId}`)
        .then(res => {
            if(asPage){
                alert("This Invoice has been deleted. You will be redirected to the sale of this invoice.")
                history.push(`/app/sales/manage/${invoice.sale}`)
            }else{
                alert("This Invoice has been deleted.")
                refreshSalePage();
            }
        })
    }

    if(invoice){
        return(
            <div>
                {asPage ? <Crumbs prevPaths={[['/app/invoices', 'Invoices']]} page={invoice.short_name}/> : null}
                <div className="page-content-wrapping">
                    <Invoice 
                        invoice={invoice}
                        files={files}
                        uploadFile={() => setUploadModal(true) } 
                        updateInvoice={(updates) => updateInvoice(updates)}
                        addNote={() => setNoteModal(true)}
                        removeInvoice={() => setDeleteModal(true)}
                    />
                    {deleteModal ? <DeleteInvoiceModal 
                        name={invoice.short_name}
                        removeInvoice={() => removeInvoice()}
                        cancelDelete={() => setDeleteModal(false)}
                    /> : null}
                    {noteModal ? <AddNote
                        submitNote={(note) => submitNote(note)}
                        close={() => setNoteModal(false)}
                    /> : null}
                    {uploadInvoice ? 
                        <UploadModal filename={uploadInvoice}/>  : 
                     uploadModal ? 
                        <UploadFileModal
                            uploadFile={(file) => uploadInvoiceFile(file)}
                            closeModal={() => setUploadModal(false)}
                        /> 
                    : null}
                    
                </div>
            </div>
        );  
    }else{
        return <Spinner/>
    }
}

export default withAuthProvider(withRouter(InvoiceContainer));