import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label, Input, 
    InputGroup, InputGroupAddon, FormFeedback } from 'reactstrap';
import './newSale.styles.scss';
import AdInputs from './adInputs.component';
import InvoiceInputs from './invoiceInputs.component';
import validateEntries from './validateEntries';
import ConfirmSubmit from './confirmSubmit.modal';
import { submitForm } from './../../redux/newSale/newSale.actions';
import withAuthProvider from './../../AuthProvider';
import { withRouter } from 'react-router-dom';

const NewSaleForm = ({reps, advertisers, sizes, submitForm, getAccessToken, match}) => {
    //STATE TO ACTIVATE CHANGES WITHIN FORM PAGE
    const [paymentDetails, setPaymentDetails] = useState(
        {
            isSingledelay: false, 
            isMultiple: false,
            invalidTotal: false,
        }
    );
    const [submitRequested, setSubmitRequested] = useState(false);
    const [advertiserName, setAdvertiserName] = useState(false);
    
    //STATE TO STORE INPUT FOR INVOICE
    const [numberOfPayments, setNumberOfPayments] = useState(1);
    const [terms, setTerms] = useState("NET 30");
    const [price, setPrice] = useState([0]);
    const [advertiser, setAdvertiser] = useState(false);
    const [sendOn, setSendOn] = useState([new Date()]);
    const [order, setOrder] = useState([{notes: "", premium: false, run_status: "scheduled"}]);
    const [salesRep, setSalesRep] = useState(false);
    const [invoiceNotes, setInvoiceNotes] = useState([]);
    
    const total = price[0];
    const {invalidTotal} = paymentDetails;

     //update info from api on mount
     useEffect(() => {
        if(match.params.organization){
            const value = match.params.organization;
            const advertiser = advertisers.find(({id}) => id === value);
            if(advertiser){
                setAdvertiser(value);
                setAdvertiserName(advertiser.name);
            }
        }
    }, [match.params.company]); // eslint-disable-line

    const handleSubmit = () => {
        if(validateEntries({advertiser, salesRep, order, invalidTotal, price})){
            setSubmitRequested(true);
        }
    }

    const confirmSubmit = async shouldSubmit => {
        if(shouldSubmit){
            
            const token = await getAccessToken(['api://9523c67c-63d0-4a6a-8354-6750e64abaa3/TLC.Read']);
            const verify = {
                headers: { 'access-token': token }
            }

            submitForm(
                {
                    numberOfPayments: numberOfPayments, 
                    terms:terms, 
                    price: price, 
                    advertiser: advertiser, 
                    sendOn: sendOn, 
                    order: order, 
                    salesRep: salesRep,
                    invoiceNotes: invoiceNotes,
                    verify
                }
            );
        }else{
            setSubmitRequested(false);
        }
    }

    const handleNumberOfPaymentsChange = e => {
        const { value } = e.target;
        setNumberOfPayments(value);
        updateInitialDates(value);
    }

    const handleTermsChange = e => {
        const { value } = e.target;
        setTerms(value);
    }

    const handleAdvertiserChange = e => {
        const { value } = e.target;
        const advertiser = advertisers.find(({id}) => id === value);
        setAdvertiser(value);
        setAdvertiserName(advertiser.name);
    }

    const handleSendOnChange = e => {
        const { value, id } = e.target;
        if(id === "single-delay"){
            setSendOn([value]);
        }else{
            const newSendOn = [...sendOn];
            newSendOn[id] = value;
            setSendOn(newSendOn);
        }
        //change at its index and call set...
    }

    const handleInvoiceNotesChange = e => {
        const { value, id } = e.target;
        const newNotes = [...invoiceNotes];
            newNotes[id] = value;
            setInvoiceNotes(newNotes)
        //change at its index and call set...
    }

    const getInitialDates = numberOfPayments => {
        let newSendOn = [];
        for (let index = 0; index < numberOfPayments; index++) {
            let d = new Date();
            d.setMonth(d.getMonth()+index);
            newSendOn[index] = d.getFullYear() + "-" + ("0" + (d.getMonth() +1) ).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
        }
        setSendOn(newSendOn);
    }

    const updateInitialDates = numberOfPayments => {
        let newSendOn = [];

        if(numberOfPayments > sendOn.length){
            newSendOn = [...sendOn]
            for (let index = sendOn.length; index < numberOfPayments; index++) {
                let d = new Date();
                d.setMonth(d.getMonth()+index);
                newSendOn[index] = d.getFullYear() + "-" + ("0" + (d.getMonth() +1) ).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
            }
        }else{
            for (let index = 0; index < numberOfPayments; index++) {
                newSendOn[index] = sendOn[index];
            }
        }
        
        setSendOn(newSendOn);
    }

    const handleSalesRepChange = e => {
        const { value } = e.target;
        setSalesRep(value);
    }

    const handlePaymentsChange = e => {
        const { value } = e.target;
        if(value === "single"){
            getInitialDates(1)
            setNumberOfPayments(1)
            setPaymentDetails({isSingledelay: false, isMultiple: false})
        }else if(value === "single-delay"){
            getInitialDates(1)
            setNumberOfPayments(1)
            setPaymentDetails({isSingledelay: true, isMultiple: false})
        }else if(value === "multiple"){
            getInitialDates(3)
            setNumberOfPayments(3)
            setPaymentDetails({isSingledelay: false, isMultiple: true})
        }
    }

    const handleTotalChange = e => {
        const { value } = e.target;
        setPrice([value]);
        setPaymentDetails({...paymentDetails, invalidTotal: false})
        if(numberOfPayments > 1){
            updateSplits(value);
        }
    }

    //Update rows and calculate split every time number of payments changes
    /* eslint-disable */
    useEffect(() => {
        if(numberOfPayments > 1){
            updateSplits(total)
        }
    }, [numberOfPayments]);
    /* eslint-enable */

    const updateSplits = (total) => {
        const newPrice = [total];
        let split = (total / numberOfPayments);
        split = Math.floor(split* 100) / 100;
        let remainingCents = ((total * 100) - ((split * numberOfPayments) * 100)).toFixed();

        for (let index = 1; index <= numberOfPayments; index++) {
            if(index <= parseInt(remainingCents)){
                newPrice[index] = (split+0.01).toFixed(2);
            }else{
                newPrice[index] = split.toFixed(2);
            }
        }
        setPrice(newPrice);
    }

    const updatePrice = (newPrice, validTotal) => {
        setPrice(newPrice)
        if(validTotal){
            setPaymentDetails({...paymentDetails, invalidTotal: false})
        }else{
            setPaymentDetails({...paymentDetails, invalidTotal: true})
        }
    }

    return(
        <div>
        <Form>
            <FormGroup style={{marginBottom: "0"}}>
                <Label for="advertiser">Organization</Label>
                <Input required onChange={handleAdvertiserChange} type="select" name="advertiser" id="advertiser" value={advertiser}>
                <option value={false} disabled hidden>Choose An Advertiser... </option>
                {
                    advertisers.map(({id, name, address}) => {
                        return <option key={name} value={id}>{name} ({address.state}, {address.zip})</option>
                    })
                }
                </Input>
            </FormGroup>
            <p style={{marginBottom: "1rem", fontSize: "12px", color: "red"}}>*To search list, select the drop-down and type. The list should jump to the closest match.*</p>
            <FormGroup>
                <Label for="salesRep">Sales Rep</Label>
                <Input required onChange={handleSalesRepChange} type="select" name="salesRep" id="salesRep" value={salesRep}>
                <option value={false} disabled hidden> Choose Sales Representative...</option>
                {
                    reps.map((name) => {
                        return <option key={name} value={name}>{name}</option>
                    })
                }
                </Input>
            </FormGroup>

            <hr className="spacer"/>

            <h2 className="section-header">Order Details</h2>
                
                <AdInputs 
                    sizes={sizes} 
                    order={order}
                    setOrder={(newOrders) => setOrder(newOrders)}
                />

            <hr className="spacer"/>

            <h2 className="section-header">Payment Details</h2>
            <FormGroup>
                <Label for="invoice-charge">Total Sale Price</Label>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Input
                        min={0} 
                        onChange={handleTotalChange} 
                        value={total} 
                        type="number" 
                        name="price" 
                        id="price" 
                        step="100"
                        />
                    <FormFeedback>Payments do not add up to total</FormFeedback>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label for="terms">Terms of Sale</Label>
                <Input onChange={handleTermsChange} type="select" name="terms" id="terms">
                    <option>NET 30</option>
                    <option>NET 45</option>
                    <option>NET 60</option>
                    <option>Due upon receipt</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="payments">Payment Options</Label>
                <Input onChange={handlePaymentsChange} type="select" name="payments" id="payments">
                    <option value="single">Single Payment - Immediate Invoice</option>
                    <option value="single-delay">Single Payment - Invoiced at Future Date</option>
                    <option value="multiple">Multiple Payments</option>
                </Input>
            </FormGroup>

            { paymentDetails.isSingledelay ? 
            <FormGroup>
                <Label for="single-delay">Send Invoice On:</Label>
                <Input
                onChange={handleSendOnChange}
                value={sendOn[0]}
                type="date"
                name="single-delay"
                id="single-delay"
                placeholder="Invoice to be sent on"
                />
            </FormGroup> 
            :null}

            { paymentDetails.isMultiple  ?
            <FormGroup>    
                <Label for="numberOfPayments">Number of Payments</Label>
                    <Input
                    invalid={numberOfPayments < 2 ? true : false}
                    min={2}
                    value={numberOfPayments}
                    onChange={handleNumberOfPaymentsChange}
                    type="number"
                    name="numberOfPayments"
                    id="numberOfPayments"
                    placeholder="Select Amount to Divide Sale"
                    />     
                <div className="multiple-invoice">                
                    <InvoiceInputs  
                        numberOfPayments={numberOfPayments}
                        price={price}
                        updatePrice={updatePrice}
                        handleSendOnChange={handleSendOnChange}
                        sendOn={sendOn}
                        notes={invoiceNotes}
                        handleInvoiceNotesChange={handleInvoiceNotesChange}
                    />      
                </div>
            </FormGroup>
            : 
            <FormGroup>
                <Label for={`invoice-note`}>Invoice Notes</Label>
                <Input
                    onChange={handleInvoiceNotesChange} 
                    value={invoiceNotes[1] || ''} 
                    type="textarea" 
                    name={`invoice-note`} 
                    id={1} 
                />
            </FormGroup>
            }

            <hr className="spacer"/>
            <div className="submit-button-container">
                <Button className="green-button" onClick={handleSubmit}>Submit New Sale</Button>
            </div>
        </Form>
        {submitRequested ? 
            <ConfirmSubmit
                numberOfPayments={numberOfPayments}
                terms={terms} 
                price={price}
                advertiser={advertiserName} 
                sendOn={sendOn}
                order={order}
                salesRep={salesRep}
                confirmSubmit={(shouldSubmit) => confirmSubmit(shouldSubmit)}
                submitRequested={submitRequested}
            /> 
        : null}
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    submitForm: (data) => dispatch(submitForm(data))
});

export default withAuthProvider(withRouter(connect(null, mapDispatchToProps)(NewSaleForm)));