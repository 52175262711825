import React from 'react';
import AdvertiserContainer from './../../../components/advertiser/advertiser.container';

const ManageAdvertiserPage = ({ match }) => {

    //get id of advertiser
    const id = match.params.id;

    return(
        <div>
            <AdvertiserContainer id={id} />
        </div>
    );  
    
}

export default ManageAdvertiserPage;