
var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken);
    }
  });

  return client;
}


export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client
    .api('/me')
    .select('displayName,mail,mailboxSettings,userPrincipalName')
    .get();

  return user;
}

export async function getItemDetails(accessToken, itemId) {
  try {
    const client = getAuthenticatedClient(accessToken);
    const siteId = "2cd560e3-22a1-4737-b1c0-3f3b5daba82d";

    const item = await client
    .api(`/sites/${siteId}/drive/items/${itemId}`)
    .get();

    let size = (item.size / 1000000)
    if(size > 1){ size = Math.ceil(size) + "MB"}
    else{size = Math.ceil(item.size / 1000) + " KB"}

    const details = {
      downloadUrl: item["@microsoft.graph.downloadUrl"],
      viewUrl: item.webUrl,
      name: item.name,
      size: size 
    } 
    return details;

  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function uploadFileToSharepoint(accessToken, file, name, type) {
  try {
    const client = getAuthenticatedClient(accessToken);
    const path = type + "s/" + name;
    const uploadedFile = await largeFileUpload(client, file, path)
    sendEmailOnUpload(accessToken, uploadedFile.id, name, type);
    return uploadedFile.id;

  } catch (error) {
    alert(error.message);
    return null;
    
  }
}

async function largeFileUpload(client, file, path) {
  let today = new Date();
  today = today.toDateString();

	const fileName = file.name;
	const siteId = "2cd560e3-22a1-4737-b1c0-3f3b5daba82d";
	try {
		const requestUrl = `/sites/${siteId}/drive/items/root:/${path}-${today}/${fileName}:/createUploadSession`;
		const payload = {
			item: {
				"@microsoft.graph.conflictBehavior": "fail",
				name: fileName,
			},
		};
		const fileObject = {
			size: file.size,
			content: file,
			name: fileName,
		};
		const uploadSession = await graph.LargeFileUploadTask.createUploadSession(client, requestUrl, payload);
		const uploadTask = await new graph.LargeFileUploadTask(client, fileObject, uploadSession);
		const response = await uploadTask.upload();
		return response;
	} catch (err) {
		throw err;
	}
}

export async function sendEmailOnUpload(accessToken, itemId, name, type) {

  const details = await getItemDetails(accessToken, itemId);
  const client = getAuthenticatedClient(accessToken);

  const sendMail = {
    message: {
      subject: `A File has been added to the ${type} "${name}"`,
      body: {
        contentType: "Text",
        content: 
`The file "${details.name}" has been added to "${name}." 
"${details.name}" is ${details.size} and was uploaded by the sender of this email.

Use the following link to view file:
${details.viewUrl}.

----THIS IS AN AUTOMATED MESSAGE BY THE LIVING CHURCH ADVERTISING MANAGEMENT SYSTEM----`
      },
      toRecipients: [
        {
          emailAddress: {
            address: "ManageAdvertising@livingchurch.org"
          }
        }
      ]
    },
    saveToSentItems: "false"
  };

  let res = await client.api('/me/sendMail').post(sendMail);
  return res;
}