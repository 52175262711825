import React, {useState} from 'react';
import { ListGroup, ListGroupItem, Badge, Alert, Button,  Nav, NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import './Sale.styles.scss';

const Sale = ({ sale, files, handleAddFile, deleteSale, addAd, addInvoice, setOverlay }) => {

    //hover event handlers state
    const [adHover, setAdHover] = useState({});
    const [invoiceHover, setInvoiceHover] = useState({});

    //hover event handler methods
    const handleAdHover = id => {
        if(adHover[id]){
            setAdHover({[id]: false});
        }else{
            setAdHover({[id]: true});
        }
    }

    const handleInvoiceHover = id => {
        if(invoiceHover[id]){
            setInvoiceHover({[id]: false});
        }else{
            setInvoiceHover({[id]: true});
        }
    }

    const {
    name, saleAdded, salesRep, total, invoicesPaid, nextInvoiceToBeSent, invoices, 
    adsRunning, ads, pastSendOn, TotalAds, company, totalPaid, advertiserId
    } = sale;
    
    return(
        <div className="page-content-wrapping">
        {pastSendOn ? <Alert color="warning">This sale contains one or more invoices ready to be sent!</Alert> : null}

            <div className="sale-actions">
                <Nav>
                    <NavItem>
                        <NavLink onClick={() => addAd()}>Add Ad</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => addInvoice()}>Add Invoice</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => handleAddFile()}>Add File</NavLink>
                    </NavItem>
                </Nav>
                <Nav className="sale-actions">
                    <NavItem>
                        <NavLink className="delete-button" onClick={() => deleteSale()}>Delete This Sale</NavLink>
                    </NavItem>
                </Nav>
            </div>
            <hr style={{backgroundColor: "#0062cc"}}/>
        

            <div className="single-sale-title">
                <h6 style={{padding: "0", color: "#0062cc"}}>Showing Sale</h6>
                <h3>{name}</h3>
            </div>

            <div className="single-sale">
                <div className="sale-info">
                    <div className="basic-sale-info">
                        <h6>General Info</h6>
                        <p>Company: <a href={`/app/advertisers/manage/${advertiserId}`}>{company}</a></p>
                        <p>Sales Representative: <b>{salesRep}</b></p>
                        <p>Date of Sale Entry: <b>{saleAdded}</b></p>
                    </div>
                    <div className="basic-sale-info">
                        <h6>Payment Status</h6>
                            <p>Total Amount Paid: <b>${totalPaid}</b> / <b>${total}</b></p>
                            <p>Total Invoices Paid: <b>{invoicesPaid}</b> / <b>{invoices.length}</b> </p>
                            {nextInvoiceToBeSent ? <p>Next Invoice to be sent on: <b>{nextInvoiceToBeSent}</b></p> : null}
                    </div>
                    <div className="basic-sale-info">
                        <h6>Ad Status</h6>
                            <p>Ads Run or Currently Running: <b>{adsRunning}</b> / <b>{TotalAds}</b></p>
                    </div>
                </div>
                <div className="sale-content">
                <div className="sale-content-left">
                    <ListGroup className="sale-content-list">
                    <h6>Invoices</h6>
                        {invoices.map(invoice => {
                            return(
                                <ListGroupItem 
                                onMouseEnter={() => handleInvoiceHover(invoice.invoiceId)}
                                onMouseLeave={() => handleInvoiceHover(invoice.invoiceId)} 
                                onClick={() => setOverlay({invoice: invoice.invoiceId})}   
                                tag="button" 
                                color={invoiceHover[invoice.invoiceId] ? null : invoice.statusColor}
                                key={invoice.invoiceId}
                                >
                                { invoiceHover[invoice.invoiceId] ? <div className="invoice-list-item"><span className="text-primary">Click to Manage Invoice</span> <span>{invoice.name}</span></div> : 
                                <div className="invoice-list-item">{invoice.name}<Badge color={invoice.statusColor}>{invoice.badgeText}</Badge></div>}
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                        <hr/>
                    <ListGroup className="sale-content-list">
                        <h6>Ads</h6>
                        {ads.map(ad => {
                            return(
                                <ListGroupItem
                                onMouseEnter={() => handleAdHover(ad.adId)}
                                onMouseLeave={() => handleAdHover(ad.adId)}
                                onClick={() => setOverlay({ad: ad.adId})}   
                                tag="button" 
                                color={adHover[ad.adId] ? null: "info"}
                                key={ad.adId}
                                >
                                { adHover[ad.adId] ? <div className="ad-list-item"><span className="text-primary">Click to Manage Ad</span> <span>{ad.size} - {ad.type}</span></div> : <div className="ad-list-item"><span>Size: {ad.size}</span><Badge>{ad.type}</Badge></div>}
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </div>
                <div className="sale-content-right">
                    <ListGroup className="sale-content-list">
                        <h6>All Ad & Invoice Files</h6>
                        {files.map(file => {
                            return(
                                <ListGroupItem key={file.name} className="file-list-item">
                                <span>
                                {file.name}  <Badge pill size="sm">{file.size}</Badge>
                                </span>
                                <span >
                                <Button size="sm" className="file-buttons" target="_blank" href={file.viewUrl}>View File</Button>
                                <Button size="sm" className="file-buttons" href={file.downloadUrl}>Download File</Button>
                                </span>
                                </ListGroupItem>
                            ); 
                        })}
                    </ListGroup> 
                    <hr/>
                </div>    
                </div>
            </div>
        </div>
    );
}

export default withRouter(Sale);

