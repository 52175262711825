import React from 'react';
import {FormGroup, Label, Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import './newSale.styles.scss';

const InvoiceInputs = ({numberOfPayments, price, updatePrice, handleSendOnChange, sendOn, handleInvoiceNotesChange, notes}) => {
    const invoiceInputs = [];

    const getNewTotal = () => {
        let total = 0;
        for (let index = 1; index <= numberOfPayments; index++) {
            total += parseFloat(price[index]);
        }
        return total;
    }

    const handleSplitChange = (e) => {
        const { value, id } = e.target;
        price[id] = value;
        let validTotal = true;
        /* eslint-disable */
        if(price[0] != getNewTotal()){ 
            validTotal = false;
        }
        
        updatePrice(price, validTotal);
    }

    for (let index = 1; index <= numberOfPayments; index++) {
        invoiceInputs.push(
            <div key={`invRow${index}`}>
            <Row form>
                <Col md={2}>
                    <h5>Invoice #{index}</h5>
                </Col>
                <Col md={4}>
                <FormGroup>
                    <Label for={`invoice${index}-send-on`}>Send On</Label>
                    <Input
                    type="date"
                    onChange={handleSendOnChange}
                    value={sendOn[index-1] || ''}
                    name={`invoice${index}-send-on`}
                    id={index-1}
                    placeholder="Invoice to be sent on" 
                    />
                </FormGroup>
                </Col>
                <Col md={3}>
                <FormGroup>
                    <Label for={`invoice${index}-charge`}>Charge</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                        <Input
                            onChange={handleSplitChange} 
                            value={price[index] || ''} 
                            type="number" 
                            name={`invoice${index}-charge`} 
                            id={index} 
                            step="100"/>
                    </InputGroup>
                </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col md={2}>
                </Col>

                <Col>
                    <FormGroup>
                        <Label for={`invoice${index}-note`}>Notes</Label>
                        <Input
                            onChange={handleInvoiceNotesChange} 
                            value={notes[index] || ''} 
                            type="textarea" 
                            name={`invoice${index}-note`} 
                            id={index} 
                        />
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            </div>
        );
    }
    return(
        <div>
        {invoiceInputs}
        </div>
    );
}

export default InvoiceInputs;