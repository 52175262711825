import React, {useState, useEffect} from 'react';
import { Alert, Spinner } from 'reactstrap';
import withAuthProvider from './../AuthProvider';

const Updates = ({ axios }) => {

    //initialize states
    const [dedicated, setDedicated] = useState({weekOut: 0, twoWeeksOut: 0});
    const [nextIssue, setNextIssue] = useState({total: 0, withoutResource: 0});
    const [salesAdded, setSalesAdded] = useState(0);
    const [sendSoon, setSendSoon] = useState(0);
    const [send, setSend] = useState(0);
    const [sent, setSent] = useState(0);
    const [overdue, setOverdue] = useState(0);
    const [isFetching, setIsFecthing] = useState(true);

    //get values
    useEffect(() => {
        axios.get('/api/sales/total/forweek')
        .then(res => setSalesAdded(res.data))
        .then(axios.get('/api/invoices/total/sendsoon')
        .then(res => setSendSoon(res.data))
        .then(axios.get('/api/invoices/total/send')
        .then(res => setSend(res.data))
        .then(axios.get('/api/invoices/total/sent')
        .then(res => setSent(res.data))
        .then(axios.get('/api/invoices/total/overdue')
        .then(res => setOverdue(res.data))
        .then(axios.get('/api/ads/next/amount')
        .then(res => {setNextIssue(res.data)})
        .then(axios.get('/api/ads/next/dedicated')
        .then(res => {setDedicated(res.data)})
        .then(() => setIsFecthing(false))
        ))))))
    },[]); // eslint-disable-line

    return(
        <div>
        { isFetching ? <Spinner/> : sent === 0 && send === 0 && sendSoon === 0 && overdue === 0 && salesAdded === 0 ? <h5>No Updates...</h5> : null}
        { overdue ? overdue > 1 ? 
            <a href="/app/invoices/overdue"><Alert color="danger">There are currently <b>{overdue}</b> unpaid invoices sent over 30 days ago.</Alert></a>: 
            <a href="/app/invoices/overdue"><Alert color="danger">There is currently <b>1</b> unpaid invoice sent over 30 days ago.</Alert></a>: null}
        { nextIssue.withoutResource ? nextIssue.withoutResource > 1 ? 
            <a href="/app/ads/nextissue/noresource"><Alert color="danger">There are currently <b>{nextIssue.withoutResource}</b> ads without a resource scheduled for the next issue.</Alert></a> : 
            <a href="/app/ads/nextissue/noresource"><Alert color="danger">There is currently <b>1</b> ad without a resource scheduled for the next issue.</Alert></a> : null}
        { send ? send > 1 ?
            <a href="/app/invoices/send"><Alert color="warning">There are <b>{send}</b> invoices ready to be sent.</Alert></a> : 
            <a href="/app/invoices/send"><Alert color="warning">There is <b>1</b> invoice ready to be sent.</Alert></a> : null}
        { sendSoon ? sendSoon > 1 ? 
            <a href="/app/invoices/sendsoon"><Alert color="info">There are <b>{sendSoon}</b> invoices to be sent within the next 7 days.</Alert></a> :
            <a href="/app/invoices/sendsoon"><Alert color="info">There is <b>1</b> invoice to be sent within the next 7 days.</Alert></a> : null} 
        { nextIssue.total ? nextIssue.total > 1 ? 
            <a href="/app/ads/nextissue"><Alert color="info">There are <b>{nextIssue.total}</b> ads scheduled to run in the next issue.</Alert></a> :
            <a href="/app/ads/nextissue"><Alert color="info">There is <b>1</b> ad scheduled to run in the next issue.</Alert></a> : null}
        { dedicated.weekOut ? dedicated.weekOut > 1 ? 
            <a href="/app/ads/dedicated"><Alert color="info">There are <b>{dedicated.weekOut}</b> Dedicated Emails scheduled to run within the next 7 days. (<b>{dedicated.twoWeeksOut}</b> for following 7 days)</Alert></a> :
            <a href="/app/ads/dedicated"><Alert color="info">There is <b>1</b> Dedicated Email scheduled to run within the next 7 days. (<b>{dedicated.twoWeeksOut}</b> for following 7 days)</Alert></a> : null}  
        { sent ? sent > 1 ?
            <a href="/app/invoices/sent"><Alert color="info">There are currently <b>{sent}</b> unpaid invoices.</Alert></a> : 
            <a href="/app/invoices/sent"><Alert color="info">There is currently <b>1</b> unpaid invoice.</Alert></a> : null}
        { salesAdded ?  salesAdded > 1 ?
            <a href="/app/sales"><Alert color="info"><b>{salesAdded}</b> sales have been entered in the last 7 days.</Alert></a> :
            <a href="/app/sales"><Alert color="info"><b>1</b> sale has been entered within the last 7 days.</Alert></a> : null}
        </div>
    );
}

export default withAuthProvider(Updates);