import React, { useState, useEffect } from 'react';
import {FormGroup, Label, Input, Row, Col, InputGroup, InputGroupAddon,
    Modal, ModalBody, ModalFooter, ModalHeader ,Button } from 'reactstrap';
import './../newSaleForm/newSale.styles.scss';

const UpdateInvoiceForm = ({order, setOrder, submitUpdate, closeModal}) => {
    
    const [status, setStatus] = useState(false);
    const [price, setPrice] = useState(order.price);
    const [terms, setTerms] = useState(order.terms);
    const [updateLabel, setUpdateLabel] = useState("");
    const [updateDate, setUpdateDate] = useState("");

    const [modal, setModal] = useState(true);

    const toggle = () => {
        setModal(!modal);
        closeModal();
    }

    const confirm = () => {
        setModal(!modal);
        closeModal();
        submitUpdate();
    }

    useEffect(() => {
        if(order.status === "To Be Sent"){
            setStatus("send_on");
            setUpdateLabel("Send Invoice On...")
            setUpdateDate(getToday("send_on"))
        }
        else if(order.status === "Sent"){
            setStatus("sent_on")
            setUpdateLabel("Sent On...")
            setUpdateDate(getToday("sent_on"))
        }
        else if(order.status === "Paid"){
            setStatus("paid_on")
            setUpdateLabel("Paid On...")
            setUpdateDate(getToday("paid_on"))
        }
    }, [order]); // eslint-disable-line

    const handleChange = e => {
        const { name, value } = e.target;
        setOrder({[name]: value});
    }

    const handleStatusChange = e => {
        const { value } = e.target;
        let initialDate = getToday(value);
        let status;
        setStatus(value);
        setUpdateDate(initialDate);

        if(value === "send_on"){
            setUpdateLabel("Send Invoice On...")
            status = "To Be Sent"
        }if(value === "sent_on"){
            setUpdateLabel("Sent On...")
            status = "Sent"
        }if(value === "paid_on"){
            status = "Paid"
            setUpdateLabel("Paid On...")
        }

        //pass up for API update
        setOrder({[value]: `${initialDate} GMT-0600`, status: status});
    }

    const handleStatusDateChange = e => {
        const { value } = e.target;
        setUpdateDate(value);
        setOrder({[status]: `${value} GMT-0600`});
    }

    const handlePriceChange = e => {
        const {value} = e.target;
        setPrice(value);
        setOrder({price: value});
    }

    const handleTermsChange = e => {
        const {value} = e.target;
        setTerms(value);
        setOrder({terms: value})
    }

    const getToday = (status) => {
        let d;
        if(order[status]){ d = new Date(order[status]);}
        else{d = new Date();}
        return (d.getFullYear() + "-" + ("0" + (d.getMonth() +1) ).slice(-2) + "-" + ("0" + d.getDate()).slice(-2));
    }

    return(
    <div>

    
        <Modal className="form-modal" isOpen={modal} toggle={toggle}>
            <ModalHeader>
                Update Invoice
            </ModalHeader>

            <ModalBody>
                <Row form>
                    <Col >
                        <FormGroup>
                            <Label for="status">Status</Label>
                            <Input onChange={handleStatusChange} type="select" name="status" value={status}>
                                <option value={false} disabled hidden>Choose Send Status...</option>
                                <option value="send_on">To Be Sent</option>
                                <option value="sent_on">Sent</option>
                                <option value="paid_on">Paid</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="status-change-date">{updateLabel}</Label>
                            <Input
                            type="date"
                            onChange={handleStatusDateChange}
                            value={updateDate}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                    
                { !order.invoice_no && (status === "sent_on" || status === "paid_on") ?
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label for="status-change-date">Invoice Number</Label>
                            <Input
                            type="text"
                            name="invoice_no"
                            onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                : null}

                <Row form>
                    <Col>
                        <FormGroup>
                            <Label for="terms">Terms of Sale</Label>
                            <Input onChange={handleTermsChange} type="select" name="terms" id="terms" value={terms}>
                                <option>NET 30</option>
                                <option>NET 45</option>
                                <option>NET 60</option>
                                <option>Due upon receipt</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="price">Amount Due</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input
                                    onChange={handlePriceChange} 
                                    value={price} 
                                    type="number" 
                                    name="price"
                                    id="price" 
                                    step="100"/>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle}>Cancel</Button>
                <Button color="primary" onClick={() => confirm()}>Update Invoice</Button>
            </ModalFooter>
        </Modal>


        
    </div>
    );
}


export default UpdateInvoiceForm;