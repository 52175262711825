import React from 'react';
import Crumbs from './../../components/crumbs.component';
import { Nav, NavItem, NavLink} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import SalesList from './../../components/salesList/SalesList.component';
import './sales.page.styles.scss';

const SalesPage = ({ history }) => (
    <div>
        <Crumbs page="Sales"/>
            <Nav>
                <NavItem>
                    <NavLink onClick={() => history.push("/app/sales/add") }>Add New Sale</NavLink>
                </NavItem>
            </Nav>
            <hr/>
        <div className="sales-page">
            <h6 style={{padding: "0", color: "#0062cc"}}>Sales</h6>
            <hr style={{backgroundColor: "#0062cc"}}/>
            <SalesList/>
        </div>
    </div>
);

export default withRouter(SalesPage);