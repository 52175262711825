import newSaleActionTypes from './newSale.actionTypes';
import axios from 'axios';

export const submitForm = (data) => {
    return async (dispatch) => {
        try {
            //dispatch start message
            dispatch({ type: newSaleActionTypes.ENTER_NEW_SALE_START });

            //PHASE I: Enter Sale into Sales Collection
            axios.post('/api/sales', {
                advertiser: data.advertiser, 
                number_of_payments: data.numberOfPayments,
                total: data.price[0],
                sales_rep: data.salesRep
            }, data.verify)
            .then((res) => {
                const saleId = res.data._id;
                const {short_name} = res.data

                //PHASE II: Enter Ads into Ads Collection
                axios.post('/api/ads', {
                    order: data.order,
                    advertiser: data.advertiser,
                    sale: saleId
                }, data.verify)
                .then((res) => {
                    //PHASE III: Enter Invoices into Invoices Collection
                    axios.post('/api/invoices', {
                        terms: data.terms,
                        status: "To Be Sent",
                        send_on: data.sendOn,
                        price: data.price,
                        advertiser: data.advertiser,
                        sale: saleId,
                        notesArray: data.invoiceNotes
                    }, data.verify)
                    .then((res) => {
                        dispatch({
                            type: newSaleActionTypes.ENTER_NEW_SALE_SUCCESS,
                            payload: {saleId, short_name, numberOfPayments: data.numberOfPayments, numberOfAds: data.order.length}
                        });
                    })
                    .catch(err => { throw err; });
                })
                .catch(err => { throw err; });
            })
            .catch(err => { throw err; });

        } catch (err) {
            dispatch({ 
                type: newSaleActionTypes.ENTER_NEW_SALE_FAILURE,
                payload: err.message
            });
        }
    }
}

export const clearSale = () => {
    return({
        type: newSaleActionTypes.CLEAR_SALE
    });
}
