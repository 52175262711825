import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { refreshData } from './../../redux/data/data.actions';
import { selectReps, selectAdvertisers, selectSizes } from './../../redux/data/data.selectors';
import { selectNewSale } from './../../redux/newSale/newSale.selectors';
import { clearSale } from './../../redux/newSale/newSale.actions';
import NewSaleForm from './newSaleForm.component';
import { Spinner, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import withAuthProvider from './../../AuthProvider';
import './newSale.styles.scss';

const NewSaleFormContainer = ({ clearSale, history, checkForRefresh, reps, advertisers, sizes, status, getAccessToken }) => {

    useEffect(() => {
        const initPage = async () => {
            const token = await getAccessToken(['api://9523c67c-63d0-4a6a-8354-6750e64abaa3/TLC.Read']);
            const verify = {
                headers: { 'access-token': token }
            }
            checkForRefresh(verify);
        }
        initPage();
    },[checkForRefresh]); // eslint-disable-line

    if(status.requested && status.successfulSubmission){
        return(
            <div>
                <h2 className="success-title"><span>{status.response.short_name}</span> has successfully been entered.</h2>
                <h5>Details:</h5>
                <ul>
                    <li><b>{status.response.numberOfPayments}</b> Invoice(s) created.</li>
                    <li><b>{status.response.numberOfAds}</b> Ad(s) created.</li>
                </ul>
                <div className="success-buttons">
                    <Button onClick={() => clearSale()}>Enter Another Sale</Button>
                    <Button onClick={() => {
                            clearSale();
                            history.push(`/app/sales/manage/${status.response.saleId}`);
                        }
                    }>Go To This Sale</Button>
                </div>
            </div>
        );
    }
    if(status.requested && !status.successfulSubmission && !status.inProcess){
        return <h3>Something went wrong: {status.response}</h3>
    }
    if(reps && advertisers && sizes && !status.inProcess){
        return <NewSaleForm reps={reps} advertisers={advertisers} sizes={sizes}/>
    }else{
        return(
            <div className="submit-screen">
                <Spinner style={{ width: '20rem', height: '20rem' }} type="grow" />
            </div>
        );
    }
         
}

const mapDispatchToProps = dispatch => ({
    checkForRefresh: (verify) => dispatch(refreshData(verify)),
    clearSale: () => dispatch(clearSale())
});

const mapStateToProps = createStructuredSelector({
    reps: selectReps,
    advertisers: selectAdvertisers,
    sizes: selectSizes,
    status: selectNewSale 
});

export default withAuthProvider(withRouter(connect(mapStateToProps, mapDispatchToProps)(NewSaleFormContainer)));