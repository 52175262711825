import React, {useState, useEffect} from 'react';
import {FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import withAuthProvider from './../../AuthProvider';

const AdFrequencySingle = ({frequency, handleChange, index, issues, handleIssueChange, addIssue, removeIssue, axios}) => {
    
    const [selectIssues, setSelectIssues] = useState();
    const [years, setYears] = useState([]);
    const [year, setYear] = useState();
    const [yearSelectedMultiple, setYearSelectedMultiple] = useState({});

    //get list of issues on mount
    useEffect(() => {
        axios.get('/api/issues')
        .then(issues => {
            setSelectIssues(issues.data);
            setYears(Object.keys(issues.data));
        })
        let d = new Date();
        setYear(d.getFullYear());
    }, []); // eslint-disable-line
    
    const handleYearChange = e => {
        setYear(e.target.value);
    }

    const handleYearChangeForMultiple = e => {
        const yearSelected = {...yearSelectedMultiple};
        yearSelected[e.target.id] = e.target.value;
        setYearSelectedMultiple(yearSelected);
    }
    
    switch (frequency) {
        case "none":
        case "multiple-by-time":
            return(
                <Row form>
                    <Col>
                    <FormGroup>
                        <Label for="run_on">Run From</Label>
                        <Input
                        onChange={handleChange}
                        type="date"
                        name="run_on"
                        id={index}
                        />
                    </FormGroup>
                    </Col>
                    <Col>
                    <FormGroup>
                        <Label for="run_until">Run Until</Label>
                        <Input
                        onChange={handleChange}
                        type="date"
                        name="run_until"
                        id={index}
                        />
                    </FormGroup>
                    </Col>
                </Row>
            );
        case "single":
            return(
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label for="year">Select Year</Label>
                                <Input
                                type="select"
                                name="year"
                                id="year"
                                value={year}
                                onChange={handleYearChange}
                                >
                                <option value={0} hidden> Choose Year...</option>
                            {
                                years.map((year) => {
                                    return(
                                        <option key={year} value={year}>{year}</option>
                                    );
                                })
                            }
                            </Input>

                            <Label for="run_on">Select Issue</Label>
                                <Input
                                type="select"
                                name="run_on"
                                id={index}
                                onChange={handleChange}
                                >
                                <option value={0} hidden> Choose Issue...</option>
                            {
                                selectIssues[year] ? selectIssues[year].map(({issue_date, issue_name}) => {
                                    return(
                                        <option key={issue_name} value={issue_date}>{issue_name}</option>
                                    );
                                }) : null
                            }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            );
        case "multiple-by-selection":
            return(
                <div className="multiple-by-selection">
                    <Label>Add Issues:</Label>
                    {issues.map((issue, issuesIndex) => {
                        return(
                            <Row key={issuesIndex} form className="custom-issues-selection">
                                <Col>
                                    <FormGroup>
                                        <Label for="year">Select Year</Label>
                                            <Input
                                            type="select"
                                            name="year"
                                            id={issuesIndex}
                                            onChange={handleYearChangeForMultiple}
                                            >
                                            <option value={0} hidden> Choose Year...</option>
                                        {
                                            years.map((year) => {
                                                return(
                                                    <option key={year} value={year}>{year}</option>
                                                );
                                            })
                                        }
                                        </Input>
            
                                        <Label for="issue">Select Issue</Label>
                                            <Input
                                            type="select"
                                            name="issue"
                                            id={issuesIndex}
                                            onChange={handleIssueChange(index)}
                                            >
                                            <option value={0} hidden> Choose Issue...</option>
                                        {
                                            selectIssues[yearSelectedMultiple[issuesIndex]] ? 
                                            selectIssues[yearSelectedMultiple[issuesIndex]].map(({issue_date, issue_name}) => {
                                                return(
                                                    <option key={issue_name} value={issue_date}>{issue_name}</option>
                                                );
                                            }) : null
                                        }
                                        </Input>
                                    </FormGroup>  
                                    <hr/>
                                </Col>
                            </Row>
                        );
                    })}

                    <Button className="add-issue" onClick={() => addIssue(index)}>Add Another Issue</Button>
                    {issues.length > 1 ? <Button className="remove-issue" onClick={() => removeIssue(index)}>Remove Issue</Button> : null}
                </div>
            );         
        default:
            return null;
    }
}

export default withAuthProvider(AdFrequencySingle);