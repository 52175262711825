import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, 
    Label, Input, Row, Col, ModalHeader } from 'reactstrap';
import AdFrequencySingle from './adFreqSingle.component';
import withAuthProvider from './../../AuthProvider';

const AddAdModal = (props) => {

    const {
        submitNewAd,
        cancelAdd,
        axios
      } = props;

    useEffect(() => {
        //pass req to api 
        axios.get('/api/sizes')
        .then((res) => {
            const sizes = {web: [], print: [], other: []};
            res.data.forEach(({size, kind}) => {
                if(kind === "web"){
                    sizes.web.push(size);
                }else if(kind ==="print"){
                    sizes.print.push(size);
                }else{
                    sizes.other.push(size);
                }
            });
            setSizes(sizes)
        });
    },[]); // eslint-disable-line

    const [sizes, setSizes] = useState({});
    const [modal, setModal] = useState(true);
    const [type, setType] = useState("");
    const [empty, setEmpty] = useState(true);
    const [kind, setKind] = useState([]);
    const [frequency, setFrequency] = useState("");
    const [issues, setIssues] = useState([0]);

    const [submission, setSubmission] = useState({notes: "", premium: false, run_status: "scheduled", size: "none"});

    const toggle = () => {
        setModal(!modal);
        cancelAdd();
    }

    const addIssue = () => {
        const newIssues = [...issues];
        newIssues.push("");
        setIssues(newIssues);
    }

    const removeIssue = () => {
        const newIssues = [...issues];
        newIssues.pop();
        setIssues(newIssues);
    }

    const handleChange = e => {
        const { name } = e.target;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        if(name === "run_on"){
            setSubmission({...submission, [name]: [value]});
        }else{
            setSubmission({...submission, [name]: value});
        }
    }

    const handleAdType = e => {
        const { value, name } = e.target;

        setType(value);
        setEmpty(false);

        if(value === "web"){
            setKind(sizes.web);
        }else if(value === "print"){
            setKind(sizes.print);
        }else if(value === "other"){
            setKind(sizes.other);
        }

        setSubmission({
            [name]: value, 
            size: "none", 
            notes: submission.notes, 
            premium: submission.premium, 
            run_status: submission.run_status
        });
    }

    const handleFrequencyChange = e => {
        const { value, name } = e.target;

        setFrequency(value);
        setIssues([0]);
        
        let newSubmission = {
            [name]: value,
            type: submission.type, 
            size: submission.size,
            notes: submission.notes, 
            premium: submission.premium, 
            run_status: submission.run_status
        };

        //keep run_on value if switching between single and multiple-by-time
        if((frequency === "single" || 
            frequency === "multiple-by-time") && 
            (value === "single" || value === "multiple-by-time"
            )
        ){ newSubmission = {...newSubmission, run_on: submission.run_on};}

        setSubmission(newSubmission)
    }

    const handleIssueChange = index => e => {
        const { id, value } = e.target;

        const newIssues = [...issues];
        newIssues[id] = value;

        setIssues(newIssues);
        setSubmission({...submission, run_on: newIssues});
    }

    const handleSubmit = () => {
        //validate that neccasary fields filled out
        if(!submission.type){
            alert("You have not selected an Ad Type for this ad.");
        }else if(submission.size === "none"){
            alert("You have not selected the size for this ad.");
        }else if(!submission.run_until && !submission.run_on){
            alert("You have not selected any dates for this ad.");
        }else if(submission.type !== "print" && (submission.run_until && !submission.run_on)){
            alert("You have not selected a Run From Date.");
        }else if(submission.type !== "print" && !submission.run_until ){
            alert("You have not selected a Run Until Date.");
        }else if(submission.type === "print" && frequency === "multiple-by-time" && !submission.run_on){
            alert("You have not selected a Run From Date.");
        }else if(submission.type === "print" && frequency === "multiple-by-time" && !submission.run_until){
            alert("You have not selected a Run Until Date.");
        }else if(submission.type === "print" && frequency !== "multiple-by-time" && !submission.run_on){
            alert("You have not selected any issue Dates.");
        }else{
            submitNewAd(submission);
        }
    }

return (
    <div>
      <Modal className="form-modal" isOpen={modal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
        toggle={toggle}>

        <ModalHeader>
        Add New Ad
        </ModalHeader>

        <ModalBody>
        
                    <Row form>
                        <Col>
                            <FormGroup>
                                <Label for="kind">Ad Type</Label>
                                <Input onChange={handleAdType} type="select" name="type" defaultValue="none">
                                    <option value="none" hidden> Choose type of ad...</option>
                                    <option value="print">Print</option>
                                    <option value="web">Web</option>
                                    <option value="other">Other</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>  
                                <Label for="adSize">Size</Label>
                                <Input onChange={handleChange} type="select" name="size" disabled={empty} value={submission.size}>
                                <option value="none" hidden> Choose ad size...</option>
                                {
                                    kind.map((size) => {
                                        return <option key={size} value={size}>{size}</option>
                                    })
                                }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    {type !== "none" ? <hr/> : null}

                    {type === "print" ? 

                        <div>
                            <FormGroup>
                                <Label for="frequency">Frequency</Label>
                                <Input onChange={handleFrequencyChange} type="select" name="frequency" defaultValue="none">
                                    <option value="none" hidden> Choose Frequency...</option>    
                                    <option value="single">Single Issue</option>
                                    <option value="multiple-by-time">Multiple Issues - Time Period</option>
                                    <option value="multiple-by-selection">Multiple Issues - Custom Selections</option>
                                </Input>
                            </FormGroup>
                            <AdFrequencySingle 
                                frequency={frequency} 
                                handleChange={handleChange} 
                                index={0}
                                issues={issues}
                                handleIssueChange={handleIssueChange}
                                addIssue={addIssue}
                                removeIssue={removeIssue}/>
                        </div>
                        
                    : type !== "none" ? 
                                <AdFrequencySingle frequency="none" handleChange={handleChange} index={0}/>
                    : null}

                    <hr/>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="run_status">Run Status</Label>
                                <Input onChange={handleChange} type="select" name="run_status" defaultValue="scheduled">
                                    <option value="scheduled">Scheduled</option>
                                    <option value="run">Run</option>
                                    <option value="tentative">Tentative</option>
                                    <option value="hold">Hold</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                        
                    <FormGroup check>
                        <Label check>
                            <Input onChange={handleChange} type="checkbox" name="premium"/>
                            Premium Placement?
                        </Label>
                    </FormGroup>
                       
                    <FormGroup>
                        <Label for="notes">Notes</Label>
                        <Input onChange={handleChange} type="textarea" name="notes" />
                    </FormGroup>
                    
        </ModalBody>
        
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={() => handleSubmit()}>Add New Ad</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default withAuthProvider(AddAdModal);
