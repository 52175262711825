import React, { useState, useEffect } from 'react';
import Crumbs from './../crumbs.component';
import { Spinner } from 'reactstrap';
import Advertiser from './advertiser.component';
import AddNote from './../notes/AddNote.modal';
import withAuthProvider from './../../AuthProvider';
import UpdateAdvertiserForm from './updateAdvertiserForm.component';

const AdvertiserContainer = ({ id, axios }) => {

    const [advertiserData, setAdvertiser] = useState();
    const [address, setAddress] = useState();
    const [noteModal, setNoteModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [sales, setSales] = useState([]);

    //update info from api everytime page loads
    useEffect(() => {
        //populate state
        axios.get(`/api/advertisers/${id}`)
        .then(res => {
            const {company_name, account_number, contact_person, phone, email, notes} = res.data;
            setAdvertiser({company_name, account_number, contact_person, phone, email, notes});
            setAddress(res.data.address);
        })
        .then(axios.get(`/api/sales/byadvertiser/${id}`)
        .then(res => setSales(res.data.map((sale) => {
            console.log(sale)
            return {
                name: sale.short_name, 
                id: sale._id, 
                added: new Date(sale.sale_added)
            }}))))  
    }, [id]) // eslint-disable-line


    const updateAdvertiser = updates => {
        if (!updates.isEmpty){
            //axios update call
            axios.post(`/api/advertisers/${id}`, updates)
            .then(res => {
                alert("Advertiser information Updated.")
                const {company_name, account_number, contact_person, phone, email, notes} = res.data;
                setAdvertiser({company_name, account_number, contact_person, phone, email, notes});
                setAddress(res.data.address);
            }).catch(err => {alert(`Something went wrong: ${err.message}`)
        })
        }else{
            alert("No changes have been entered.")
        }
    }

    const submitNote = note => {
        if (note.note !== ""){
            //axios update call
            console.log(note.user)
            axios.post(`/api/advertisers/${id}/newNote`, note)
            .then(res => {
                const {company_name, account_number, contact_person, phone, email, notes} = res.data;
                setAdvertiser({company_name, account_number, contact_person, phone, email, notes});
                setAddress(res.data.address);
            }).catch(err => alert(`Something went wrong: ${err.message}`))
        }else{
            alert("The note you attempted to submit is empty");
        }
    }

    if(advertiserData && address){
        return(
            <div>
                <Crumbs prevPaths={[['/app/advertisers', 'Advertisers']]} page={advertiserData.company_name} /> 
                <div className="page-content-wrapping">
                    <Advertiser 
                        advertiserData={advertiserData}
                        sales={sales}
                        address={address}
                        addNote={() => setNoteModal(true)}
                        updateAdvertiser={() => setUpdateModal(true)}
                        id={id}
                    />
                    {noteModal ? <AddNote
                        submitNote={(note) => submitNote(note)}
                        close={() => setNoteModal(false)}
                    /> : null}
                    {updateModal ? <UpdateAdvertiserForm 
                        advertiser={advertiserData}
                        address={address} 
                        submitUpdates={update => updateAdvertiser(update)}
                        closeModal={() => setUpdateModal(false)}
                    /> : null}
                </div>
            </div>
        );  
    }else{
        return <Spinner/>
    }
}

export default withAuthProvider(AdvertiserContainer);