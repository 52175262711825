import React, { useState } from 'react';
import Crumbs from './../../components/crumbs.component';
import AdvertisersList from './../../components/advertisersList/advertisersList.component';
import Search from '../../components/search/Search.component';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import './advertisers.page.styles.scss';

const AdvertisersPage = ({history}) => {

    const [query, setQuery] = useState("0");
    const [stagedQuery, setStagedQuery] = useState("");

    const submitSearch = () => {
        setQuery(stagedQuery);
    }

    const handleSearchChange = entry => {
        setStagedQuery(entry);

        if(entry === 0){setQuery("0");}
    }

    return(
        <div>
            <Crumbs page="Advertisers"/>
            <Nav>
                <NavItem>
                    <NavLink onClick={() => history.push("/app/advertisers/add") }>Add New Advertiser</NavLink>
                </NavItem>
            </Nav>
            <hr/>
            <div className="advertisers-page">
                <h6 style={{padding: "0"}}>Advertisers</h6>
                <hr/>
                <Search 
                    placeholder="Search by Company Name, Account Number, or Contact Person" 
                    submitSearch={() => submitSearch()}
                    handleSearchChange={entry => handleSearchChange(entry)}
                /> 
                <AdvertisersList className="advertisers-list" search={query}/>
            </div>
        </div>
    );
}

export default withRouter(AdvertisersPage);