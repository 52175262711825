import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const DeleteSaleModal = (props) => {
  const {
    name,
    removeSale,
    cancelDelete
  } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => {
      setModal(!modal);
      cancelDelete();
  }

  return (
    <div>
      <Modal isOpen={modal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
        toggle={toggle}>
        <ModalBody>
        <p>You are about to permanently delete this Sale ({name}). This action cannot be reversed.</p>
        <p>All Invoices and Ads listed as part of this sale will be removed</p> 
        <b>Are you sure you want to delete this Sale?</b>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggle}>Cancel</Button>
            <Button color="danger" onClick={() => removeSale()}>Yes I'm Sure</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteSaleModal;