import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import dataReducer from './data/data.reducer';
import newSaleReducer from './newSale/newSale.reducer';


const rootReducer = combineReducers({
    auth: authReducer,
    data: dataReducer,
    newSale: newSaleReducer
});

export default rootReducer;