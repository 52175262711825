import React, { useState, useEffect } from 'react';
import Crumbs from './../../../components/crumbs.component';
import { Spinner } from 'reactstrap';
import withAuthProvider from '../../../AuthProvider';
import { uploadFileToSharepoint, getItemDetails } from '../../../GraphService';
import { config } from '../../../Config';
import Sale from './../../../components/sale/Sale.component';
import UploadModal from './../../../components/upload.modal';
import AddFileModal from './../../../components/sale/AddFile.modal';
import DeleteSaleModal from './../../../components/sale/deleteSale.modal';
import AddAdModal from './../../../components/sale/addAd.modal';
import AddInvoiceModal from './../../../components/sale/addInvoice.modal';
import AdContainer from './../../../components/ad/ad.container';
import InvoiceContainer from './../../../components/invoice/invoice.container';
import './manageSalePage.styles.scss';

const ManageSalePage = ({ match, getAccessToken, history, axios }) => {

    const [sale, setSale] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [showAddFileModal, setShowAddFileModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [addAdModal, setAddAdModal] = useState(false);
    const [addInvoiceModal, setAddInvoiceModal] = useState(false);
    const [overlay, setOverlay] = useState(false);

    //get id of sale
    const saleid = match.params.saleId;

    //update info from api everytime page loads
    useEffect(() => {
        axios.get(`/api/sales/${saleid}`)
            .then(res => {
                setSale(res.data);
                getFiles(res.data.files);
            })
    }, [saleid]) // eslint-disable-line

    const refreshPage = () => {
        axios.get(`/api/sales/${saleid}`)
            .then(res => {
                setSale(res.data);
                getFiles(res.data.files);
            })
    }

    const getFiles = async (files) => {
        const filesPromises = files.map(async file => {
            const token = await getAccessToken(config.scopes);
            const details = await getItemDetails(token, file);
            return details;
        });
        Promise.all(filesPromises)
        .then(filesArray => {
            //filter out where null
            setFiles(filesArray.filter(file => file !== null));
        })
        .catch(e => {
            console.error(e);
        })
    }

    const uploadFile = async (file, adsIncluded) => {
        setUploading(file.name);
        setShowAddFileModal(false);
        const token = await getAccessToken(config.scopes);
        const response = await uploadFileToSharepoint(token, file, sale.company, "Sale")
        setUploading(false);
        if(response){ 
            //axios update call
            axios.post(`/api/sales/${saleid}/addFile`, {file: response, adsIncluded: adsIncluded})
            .then(res => {
                //update file list on frontend
                getFiles(res.data.files)
                alert(file.name + " Successfully Uploaded to Sharepoint.")
            })
        }
    }

    const submitAd = submission => {
        //axios post call
        axios.post(`/api/ads`, {order: [{...submission}], sale: saleid, advertiser: sale.advertiserId})
        .then(res => {
            alert("New Add has been added to sale.")
            setAddAdModal(false);

            axios.get(`/api/sales/${saleid}`)
            .then(res => {
                setSale(res.data);
            });
        })
    }

    const submitInvoice = submission => {
        //axios post call
        axios.post(`/api/invoices/addition`, {...submission, sale: saleid, advertiser: sale.advertiserId, company_name: sale.company})
        .then(res => {
            alert("New Invoice has been added to sale.")
            setAddInvoiceModal(false);

            axios.get(`/api/sales/${saleid}`)
            .then(res => {
                setSale(res.data);
            });
        })
    }

    const handleAddFile = () => {
        setShowAddFileModal(true);
    }

    const removeSale = () => {
        //axios update call
        axios.delete(`/api/sales/${saleid}`)
            .then(res => {
                alert("This sale has been deleted. You will be redirected to the sales list.")
                history.push(`/app/sales`)
            })
    }

    const closeOverlay = () => {
        refreshPage();
        setOverlay(false);
    }

    if(sale){
        return(
            <div>
                {uploading ? <UploadModal filename={uploading}/> : null}
                {showAddFileModal ? 
                    <AddFileModal
                        uploadFile={(file, adsIncluded) => uploadFile(file, adsIncluded)}
                        closeModal={() => setShowAddFileModal(false)}
                        ads={sale.ads}
                /> : null}

                {deleteModal ? <DeleteSaleModal 
                    name={sale.name}
                    removeSale={() => removeSale()}
                    cancelDelete={() => setDeleteModal(false)}
                /> : null}

                {addAdModal ? <AddAdModal
                    submitNewAd={(submission) => submitAd(submission)}
                    cancelAdd={() => setAddAdModal(false)}
                /> : null}

                {addInvoiceModal ? <AddInvoiceModal
                    submitNewInvoice={(submission) => submitInvoice(submission)}
                    cancelInvoice={() => setAddInvoiceModal(false)}
                /> : null}
                {overlay.ad ? 
                    <div className="sale-overlay-box"><div className="sale-overlay">
                        <span className="close-btn" onClick={() => closeOverlay()}>&#10005;</span>
                        <AdContainer adId={overlay.ad} refreshSalePage={() => closeOverlay()}/>
                    </div></div> : null}
                {overlay.invoice ? 
                    <div className="sale-overlay-box"><div className="sale-overlay">
                        <span className="close-btn" onClick={() => closeOverlay()}>&#10005;</span>
                        <InvoiceContainer invoiceId={overlay.invoice} refreshSalePage={() => closeOverlay()}/>
                    </div></div> : null}

                <Crumbs prevPaths={[['/app/sales', 'Sales']]} page={sale.name}/>
                <Sale 
                    sale={sale} 
                    files={files} 
                    handleAddFile={() => handleAddFile()}
                    deleteSale={() => setDeleteModal(true)}
                    addAd={() => setAddAdModal(true)}
                    addInvoice={() => setAddInvoiceModal(true)}
                    refreshPage={() => refreshPage()}
                    setOverlay={input => setOverlay(input)}
                />
            </div>
        );  
    }else{
        return <Spinner/>
    }
}

export default withAuthProvider(ManageSalePage);