import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import  { BrowserRouter, Switch, Route } from 'react-router-dom';
import dedicatedEmail from './pages/dedicatedEmails/dedicated';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/dedicated-emails-calendar" component={dedicatedEmail}/>
      <Route path="/" component={App}/>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
