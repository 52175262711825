import React, { useState } from 'react';
import {
    Collapse, 
    Navbar, 
    NavbarToggler, 
    NavbarBrand,
    Nav, 
    NavItem, 
    Container,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { setUser } from './../redux/auth/auth.actions';
import { connect } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.css';

function UserAvatar(props) {
    // If a user avatar is available, return an img tag with the pic
    if (props.user.avatar) {
      return <img
        src={props.user.avatar} alt="user"
        className="rounded-circle align-self-center mr-2"
        style={{ width: '32px' }}></img>;
    }
  
    // No avatar available, return a default icon
    return <i
      className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
      style={{ width: '32px' }}></i>;
  }

  function AuthNavItem(props) {
    // If authenticated, return a dropdown with the user's info and a
    // sign out button
    if (props.isAuthenticated) {
      return (
        <UncontrolledDropdown>
          <DropdownToggle nav caret>
            <UserAvatar user={props.user} />
          </DropdownToggle>
          <DropdownMenu right>
            <h5 className="dropdown-item-text mb-0">{props.user.displayName}</h5>
            <p className="dropdown-item-text text-muted mb-0">{props.user.email}</p>
            <DropdownItem divider />
            <DropdownItem onClick={props.authButtonMethod}>Sign Out</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  
    // Not authenticated, return a sign in link
    return (
      <NavItem>
        <Button
          onClick={props.authButtonMethod}
          className="btn-link nav-link border-0"
          color="link">Sign In</Button>
      </NavItem>
    );
  }

const AppNavbar = (props) => {

    React.useEffect(() => {
      props.setUser(props.user)
    }, [props.user]);

    const [navState, setNavState] = useState({
        isOpen: false
    });

    const toggle = () => {
        setNavState({isOpen: !navState.isOpen});
    };

    return(
        <div>
            <Navbar color="dark" dark expand="md">
                <Container>
                    <NavbarBrand href="/">Living Church Ad Managment</NavbarBrand>
                    
                    <NavbarToggler onClick={toggle}></NavbarToggler>
                    <Collapse isOpen={navState.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                              <RouterNavLink to="/" className="nav-link" exact>Home</RouterNavLink>
                            </NavItem>
                            <NavItem>
                              <RouterNavLink to="/app/sales" className="nav-link" exact>Sales</RouterNavLink>
                            </NavItem>
                            <NavItem>
                              <RouterNavLink to="/app/invoices" className="nav-link" exact>Invoices</RouterNavLink>
                            </NavItem>
                            <NavItem>
                              <RouterNavLink to="/app/ads" className="nav-link" exact>Ads</RouterNavLink>
                            </NavItem>
                            <NavItem>
                                <RouterNavLink to="/app/advertisers" className="nav-link" exact>Advertisers</RouterNavLink>
                            </NavItem>
                        </Nav>
                        <Nav className="justify-content-end" navbar>
                            
                            <AuthNavItem
                            isAuthenticated={props.isAuthenticated}
                            authButtonMethod={props.authButtonMethod}
                            user={props.user} />
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user))
});

export default connect(null, mapDispatchToProps)(AppNavbar);