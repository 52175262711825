import React, {useState} from 'react';
import {FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './../newSaleForm/newSale.styles.scss';
import AdFrequency from './../newSaleForm/adFrequency.component';

const UpdateRunTime = ({type, submitUpdates, closeModal}) => {
    
    const [issues, setIssues] = useState([0]);
    const [frequency, setFrequency] = useState("");
    const [updates, setUpdates] = useState({});

    const [modal, setModal] = useState(true);

    const toggle = () => {
        setModal(!modal);
        closeModal();
    }

    const addIssue = () => {
        const newIssues = [...issues];
        newIssues.push("");
        setIssues(newIssues);
    }

    const removeIssue = () => {
        const newIssues = [...issues];
        newIssues.pop();
        setIssues(newIssues);
    }

    const handleChange = e => {
        const { name } = e.target;
        const value = e.target.value;

        if(name === "run_on"){
            setUpdates({...updates, [name]: [value]});
        }else{
            setUpdates({...updates, [name]: value});
        }
    }

    const handleFrequencyChange = e => {
        const {value} = e.target;

        if(updates.run_on){
            if((frequency === "single" && value === "multiple-by-time")
                || (frequency === "multiple-by-time" && value === "single")){ // eslint-disable-line
                setUpdates({run_on: updates.run_on});
            }else{
                setUpdates({})
            }
        }else{
            setUpdates({})
        }

        setFrequency(value)
        setIssues([0]);
    }

    const handleIssueChange = index => e => {
        const { id, value } = e.target;

        const newIssues = [...issues];
        newIssues[id] = value;

        setIssues(newIssues);
        setUpdates({run_on: newIssues});
    }

    const handleSubmit = () => {
        //validate proper entries before pushing submit
        //confirm start and end date if not type print
        if(!updates.run_until && !updates.run_on){
            alert("You have not selected any dates.");
        }else if(type !== "print" && (updates.run_until && !updates.run_on)){
            alert("You have not selected a Run From Date.");
        }else if(type !== "print" && !updates.run_until ){
            alert("You have not selected a Run Until Date.");
        }else if(type === "print" && frequency === "multiple-by-time" && !updates.run_on){
            alert("You have not selected a Run From Date.");
        }else if(type === "print" && frequency === "multiple-by-time" && !updates.run_until){
            alert("You have not selected a Run Until Date.");
        }else if(type === "print" && frequency !== "multiple-by-time" && !updates.run_on){
            alert("You have not selected any issue Dates.");
        }else{
            submitUpdates(updates);
            setModal(!modal);
            closeModal();
        }
    }

    return(
        <Modal className="form-modal" isOpen={modal} toggle={toggle}>

            <ModalHeader>
                Update Run Dates
            </ModalHeader>

            <ModalBody>
            {type === "print" ? 

                <div>
                    <FormGroup>
                        <Label for="frequency">Frequency</Label>
                        <Input onChange={handleFrequencyChange} type="select" name="frequency" defaultValue="none">
                            <option value="none" hidden> Choose Frequency...</option>    
                            <option value="single">Single Issue</option>
                            <option value="multiple-by-time">Multiple Issues - Time Period</option>
                            <option value="multiple-by-selection">Multiple Issues - Custom Selections</option>
                        </Input>
                    </FormGroup>
                    <AdFrequency 
                        frequency={frequency} 
                        handleChange={handleChange} 
                        index={0}
                        issues={issues}
                        handleIssueChange={handleIssueChange}
                        addIssue={addIssue}
                        removeIssue={removeIssue}/>
                </div>
                
            : <AdFrequency frequency="none" handleChange={handleChange} index={0}/>
            }
            </ModalBody>

            <ModalFooter>
                <Button onClick={toggle}>Cancel</Button>
                <Button color="primary" onClick={() => handleSubmit()}>Submit New Dates</Button>
            </ModalFooter>
        </Modal>
    );
}

export default UpdateRunTime;