import React, {useState, useEffect} from 'react';
import { ListGroup, ListGroupItem, Badge, Spinner, Pagination,
    PaginationItem, PaginationLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import './invoicesList.styles.scss';
import withAuthProvider from './../../AuthProvider';

const InvoicesList = ({ history, status, search, axios}) => {
    
    const [invoices, setInvoices] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);

    //update info from api on mount
    useEffect(() => {
        axios.get(`/api/invoices/list/${status}/1/${search.search}/${search.dateType}/${search.date1}/${search.date2}`)
            .then(res => {
                const invoicesData = res.data.list.map(({name, invoiceId, statusColor, badgeText}) => {
                    return {name, invoiceId, statusColor, badgeText}    
                })
                const total = parseInt(res.data.pageCount);
                setPages(buildNav(total, 1));
                setPageCount(total);
                setInvoices(invoicesData);
                setIsFetching(false);
                setCurrentPage(1);
            })
    }, [search, status]); // eslint-disable-line

    const buildNav = (pageCount, page) => {
        const nav = [];

        if(page > 3){
            nav[0] = page-2;
            nav[1] = page-1;
            nav[2] = page;
            if (page+1 <= pageCount){nav[3] = page+1;}
            if (page+2 <= pageCount){nav[4] = page+2;}
        }else{
            nav[0] = 1;
            if (2 <= pageCount){nav[1] = 2;}
            if (3 <= pageCount){nav[2] = 3;}
            if (4 <= pageCount){nav[3] = 4;}
            if (5 <= pageCount){nav[4] = 5;}
        }
    return nav;
    }

    const handlePageChange = page => {
        setIsFetching(true);
        axios.get(`/api/invoices/list/${status}/${page}/${search.search}/${search.dateType}/${search.date1}/${search.date2}`)
            .then(res => {
                const invoicesData = res.data.list.map(({name, invoiceId, statusColor, badgeText}) => {
                    return {name, invoiceId, statusColor, badgeText}    
                })
                setPages(buildNav(pageCount, page));
                setInvoices(invoicesData);
                setIsFetching(false);
                setCurrentPage(page);
            })
    }

    return(
        <div>
            <div className="invoice-list-container">
                <ListGroup className="invoices-list">
                { isFetching ? <Spinner/> : invoices.length < 1 ? <p>No Results Found.</p> : null}
                {invoices.map(invoice => {
                    return(
                        <ListGroupItem 
                        action
                        onClick={() => history.push(`/app/invoices/manage/${invoice.invoiceId}`)}   
                        tag="button" 
                        color={invoice.statusColor}
                        key={invoice.invoiceId}
                        >
                        <div className="invoice-list-item">
                            {invoice.name}
                            <Badge color={invoice.statusColor}>{invoice.badgeText}</Badge>
                        </div>
                        </ListGroupItem>
                    );
                })}
                </ListGroup>
            </div>
            <Pagination aria-label="invoices Page Navigation">
            <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink first onClick={() => handlePageChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {
                  pages.map(page => {
                      return(
                          <PaginationItem key={page} active={page === currentPage ? true : undefined}>
                              <PaginationLink 
                              onClick={() => handlePageChange(page)}
                              >
                              {page}
                              </PaginationLink>
                          </PaginationItem>
                      );
                  })
              }
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink last onClick={() => handlePageChange(pageCount)} />
              </PaginationItem>
            </Pagination>
        </div>
    );
}

export default withAuthProvider(withRouter(InvoicesList));

