import React from 'react';
import {ListGroup, ListGroupItem} from 'reactstrap';

const ShowNotes = ({notes}) => (
    <ListGroup>
        {notes.map(({timeStamp, note, user}, index) => {
            let day = new Date(timeStamp);
            return(
                <ListGroupItem key={`note${index}`}>
                    {note}
                    <br/>
                    <hr/>
                    <div style={{ display: "flex",
                        paddingBottom: "6px",
                        justifyContent: "space-between"}}>
                    <sub style={{color: "#007bff"}}>{day.toLocaleDateString()}: {day.toLocaleTimeString()}</sub>
                    <sub style={{color: "#1d2124"}}>{user}</sub>
                    </div>
                </ListGroupItem>
            );
        })}
    </ListGroup>
);

export default ShowNotes;
