import newSaleActionTypes from './newSale.actionTypes';

const INITIAL_STATE = {
    requested: false,
    inProcess: false, 
    successfulSubmission: false,
    response: null
}

const newSaleReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case newSaleActionTypes.ENTER_NEW_SALE_START:
            return{
                ...state,
                requested: true,
                inProcess: true,
                successfulSubmission: false,
                response: null
            };
        case newSaleActionTypes.ENTER_NEW_SALE_SUCCESS:
            return{
                ...state,
                requested: true,
                inProcess: false,
                successfulSubmission: true,
                response: action.payload
            };
        case newSaleActionTypes.ENTER_NEW_SALE_FAILURE:
            return{
                ...state,
                requested: true,
                inProcess: false,
                successfulSubmission: false,
                response: action.payload
            };
        case newSaleActionTypes.CLEAR_SALE:
            return{
                requested: false,
                inProcess: false, 
                successfulSubmission: false,
                response: null
            }
        default: 
            return state;
    }
}

export default newSaleReducer;