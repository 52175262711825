import React from 'react';
import './newSale.styles.scss';
import Crumbs from './../../../components/crumbs.component';
import NewSaleFormContainer from '../../../components/newSaleForm/newSaleForm.container';

const NewSalePage = () => {
    return(
        <div>
            <Crumbs prevPaths={[['/app/sales', 'Sales']]} page="New Sale"/>
            <div className="new-sale-form">
                <h1>Enter New Sale</h1>
                <NewSaleFormContainer/>
            </div>
        </div>    
    );
}

export default NewSalePage;