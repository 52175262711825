import React from 'react';
import {
  Button,
  Jumbotron
} from 'reactstrap';

const SignIn = props => {
    return(
        <div>
            <Jumbotron>
            <h1>Living Church Ad Management</h1>
            <p className="lead">
            Sign in using your <b>@livingchurch.org</b> account.
            </p>
            <Button color="primary" onClick={props.signInButton}>Click here to sign in</Button>
            </Jumbotron>
        </div>
    );
}

export default SignIn;
