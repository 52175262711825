/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmSubmit = (props) => {
  const {
    numberOfPayments, 
    terms, 
    price, 
    advertiser, 
    sendOn, 
    order, 
    salesRep,
    confirmSubmit,
    submitRequested
  } = props;
  
  return (
    <div>
      <Modal isOpen={submitRequested} toggle={() => confirmSubmit(false)}>
        <ModalHeader toggle={() => confirmSubmit(false)}>Confirm Sale for <b>{advertiser}</b></ModalHeader>
        <ModalBody>
        <sub>Sales Representative: <b>{salesRep}</b></sub><br/>
        <sub>Terms of sale: <b>{terms}</b></sub><br/><br/>
        <p>Sale is set to be payed in <b>{numberOfPayments}</b> installment(s).</p>
            {sendOn.length > 1 ?
                <ol>
                {
                    sendOn.map((bill, index) => {
                        return <li key={bill}>Invoice of <b>${price[index+1]}</b> to be sent on <b>{bill}</b>.</li>
                    })
                }    
                </ol>
            : typeof(sendOn[0]) === 'object'?
                <li>Invoice of <b>${price[0]}</b> to be sent <b>immediately</b>.</li>
            :
                <li>Invoice of <b>${price[0]}</b> to be sent on <b>{sendOn[0]}</b>.</li>
            }
            <br/>
        <h5>Total Sale Price: ${price[0]}</h5>
        <hr/>
        <h5>Sale contains {order.length} ad(s).</h5>
            <ul>
            {
                order.map((ad, index) => {
                    if(ad.run_until){
                        return <li key={`ad${index}`}>(Type: <b>{ad.type}</b>, Size: <b>{ad.size}</b>) to run: <b>{ad.run_on[0]}</b> - <b>{ad.run_until}</b></li>
                    }else{
                        return( 
                            <li key={`ad${index}`}>
                            (Size: <b>{ad.size}</b>) to run in issue(s): 
                                <ol>
                                    {
                                        ad.run_on.map(issue => {
                                            return <li key={issue}><b>{issue}</b></li>
                                        })
                                    }
                                </ol>
                            </li>
                        );
                    }
                })
            }    
            </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => confirmSubmit(true)}>Confirm New Sale</Button>{' '}
          <Button color="secondary" onClick={() => confirmSubmit(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmSubmit;