import React, {useState, useEffect} from 'react';
import { Label, Button, Input, Row, Col, ButtonGroup} from 'reactstrap';
import './adsList.styles.scss';
import withAuthProvider from './../../AuthProvider';

const AdDateFilter = ({handleDateChange, query, adType, clearFilter, axios}) => {

    const [issues, setIssues] = useState();
    const [years, setYears] = useState([]);
    const [year, setYear] = useState(0);

    //get list of issues on mount
    useEffect(() => {
        axios.get('/api/issues')
        .then(issues => {
            setIssues(issues.data);
            setYears(Object.keys(issues.data));
        })
        if(query.dateType === "issue"){
            if(query.date1 !== 0){
                let d = new Date(query.date1);
                setYear(d.getFullYear());
            }else{
                let d = new Date();
                setYear(d.getFullYear());
            }
        }         
    }, [query.dateType]); // eslint-disable-line
    
    const handleYearChange = e => {
        setYear(e.target.value);
    }

    const handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        handleDateChange({[name]: value});
    }

    const setToRange = () => {
        handleDateChange({dateType: "range"});
    }

    const setToIssue = () => {
        handleDateChange({dateType: "issue"});
    }
 
    return(
        <div style={{marginBottom: '20px'}}>
        {adType === "print" ? 
            <div className="ad-date-filter-buttons">
                <Label>Filter Ads By</Label>
                <ButtonGroup size="sm" style={{'width': '350px'}}> 
                    <Button style={{'width': '175px'}} onClick={() => setToRange()} active={query.dateType === "range"}>Date Range</Button>
                    <Button style={{'width': '175px'}} onClick={() => setToIssue()} active={query.dateType === "issue"}>Issue</Button>
                </ButtonGroup>
            </div>
        : null}
            <Row form>
            { query.dateType !== "issue" ?
            <React.Fragment>
            <Col md={2}>
                
                <Label for="date1">Start Date</Label>
                    <Input
                    type="date"
                    name="date1"
                    id="date1"
                    value={query.date1}
                    onChange={handleChange}
                    />
                
            </Col>
            <Col md={2}>
                
                <Label for="date2">End Date</Label>
                    <Input
                    type="date"
                    name="date2"
                    id="date2"
                    value={query.date2}
                    onChange={handleChange}
                    />
                
            </Col>
            </React.Fragment> 
            :
            <React.Fragment> 
            <Col md={2}>
                
                <Label for="year">Select Year</Label>
                    <Input
                    type="select"
                    name="year"
                    id="year"
                    value={year}
                    onChange={handleYearChange}
                    >
                    <option value={0} hidden> Choose Year...</option>
                {
                    years ? years.map((year) => {
                        return(
                            <option key={year} value={year}>{year}</option>
                        );
                    }) : null
                }
                </Input>
            </Col>

            <Col md={2}>
                <Label for="date2">Select Issue</Label>
                    <Input
                    type="select"
                    name="date1"
                    id="date1"
                    value={query.date1}
                    onChange={handleChange}
                    >
                    <option value={0} hidden> Choose Issue...</option>
                {
                    issues[year] ? issues[year].map(({issue_date, issue_name}) => {
                        return(
                            <option key={issue_name} value={issue_date}>{issue_name}</option>
                        );
                    }) : null
                }
                </Input>
                
            </Col>
            </React.Fragment> 
            }
            <Col md={4} style={{display: "flex", alignItems: "flex-end"}}>
                <Button onClick={() => clearFilter()}>Clear Filter</Button>
            </Col>
            </Row>
        </div>
    );
}

export default withAuthProvider(AdDateFilter);
