import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col, 
    Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const UpdateAdvertiserForm = ({address, advertiser, submitUpdates, closeModal}) => {

    const [entry, setEntry] = useState({isEmpty: true, address: {...address}});
    const [modal, setModal] = useState(true);

    const toggle = () => {
        setModal(!modal);
        closeModal();
    }
    
    const handleSubmit = e => {
        e.preventDefault();
        submitUpdates(entry)
        setEntry({isEmpty: true, address: {...address}});
        toggle();
    }

    const handleChange = e => {
        const { value, name, id } = e.target;

        if(name === "address"){
            setEntry({...entry, isEmpty: false, address: {...entry.address, [id]: value}});
        }else{
            setEntry({...entry, isEmpty: false, [name]: value});
        }
    }

    return(
        <Modal className="form-modal" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Update Advertiser</ModalHeader>
            
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                <FormGroup>
                    <Label for="company_name">Company</Label>
                    <Input onChange={handleChange} type="text" 
                        name="company_name" id="company_name" 
                        placeholder="Change Company Name" 
                        defaultValue={advertiser.company_name}/>
                </FormGroup>
                <FormGroup>
                    <Label for="account_number">Account Number (If Available)</Label>
                    <Input onChange={handleChange} type="text" name="account_number" 
                        id="account_number" 
                        placeholder="Enter Corresponding Account Number from Billing Source" 
                        defaultValue={advertiser.account_number}
                        />
                </FormGroup>

                <hr/>

                <FormGroup>
                    <Label for="address1">Address</Label>
                    <Input onChange={handleChange} type="text" 
                        name="address" id="address1" 
                        placeholder="1234 Main St"
                        defaultValue={address.address1}    
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="address2">Address 2</Label>
                    <Input onChange={handleChange} type="text" name="address" 
                        id="address2" 
                        placeholder="Apartment, studio, floor..."
                        defaultValue={address.address2}     
                    />
                </FormGroup>
                <Row form>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input onChange={handleChange} type="text" name="address" id="city"
                            defaultValue={address.city}     
                        />
                    </FormGroup>
                    </Col>
                    <Col md={4}>
                    <FormGroup>
                        <Label for="state">State</Label>
                        <Input onChange={handleChange} type="text" name="address" id="state"
                            defaultValue={address.state}     
                        />
                    </FormGroup>
                    </Col>
                    <Col md={2}>
                    <FormGroup>
                        <Label for="zip">Zip</Label>
                        <Input onChange={handleChange} type="text" name="address" id="zip"
                            defaultValue={address.zip}
                        />
                    </FormGroup>  
                    </Col>
                </Row>
                    <FormGroup>
                        <Label for="country">Country</Label>
                        <Input onChange={handleChange} type="text" name="address" id="country" 
                            defaultValue={address.country}
                        />
                    </FormGroup> 

                <hr/>

                <FormGroup>
                    <Label for="contact_person">Contact Person</Label>
                    <Input onChange={handleChange} type="text" name="contact_person" 
                        id="contact_person" placeholder="Name of Contact for this advertiser" 
                        defaultValue={advertiser.contact_person}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="phone">Phone</Label>
                    <Input onChange={handleChange} type="text" name="phone" id="phone"
                        placeholder="0 123 456 7890"
                        defaultValue={advertiser.phone}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input onChange={handleChange} type="email" name="email" id="email"
                        placeholder="name@example.com"
                        defaultValue={advertiser.email}
                    />
                </FormGroup>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary">Update Advertiser</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

export default UpdateAdvertiserForm;