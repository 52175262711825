import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import NewAdvertiserForm from './NewAdvertiserForm.component';
import withAuthProvider from './../../AuthProvider';

const NewAdvertiserFormContainer = ({history, axios}) => {

    const [status, setStatus] = useState(
        {
            requested: false,
            inProcess: false, 
            successfulSubmission: false
        }
    );
    const [response, setResponse] = useState(null);

    const clearStatus = () => {
        setStatus({
            requested: false,
            inProcess: false, 
            successfulSubmission: false
        });
    }

    const submitAdvertiser = entry => {
        setStatus({...status, requested: true, inProcess: true})
        axios.post("/api/advertisers", entry)
        .then(res => {
            setResponse({
                company_name: res.data.company_name,
                account_number: res.data.account_number,
                contact_person: res.data.contact_person,
                AdvertiserId: res.data._id
            });
            setStatus({inProcess: false, requested: true, successfulSubmission: true})
        })
        .catch(err => {
            setResponse(err.message);
            setStatus({inProcess: false, requested: true, successfulSubmission: false})
        })
    }

    if(status.requested && status.successfulSubmission){
        return(
            <div>
                <h2 className="success-title"><span>{response.company_name}</span> has successfully been added.</h2>
                <h5>Details:</h5>
                <ul>
                    <li>Account Number: <b>{response.account_number}</b></li>
                    <li>Contact Person: <b>{response.contact_person}</b></li>
                </ul>
                <div className="success-buttons">
                    <Button onClick={() => clearStatus()}>Add Another Advertiser</Button>
                    <Button onClick={() => {
                        history.push(`manage/${response.AdvertiserId}`);
                        }
                    }>Go To This Advertiser</Button>
                </div>
            </div>
        );
    }
    else if(status.requested && !status.successfulSubmission && !status.inProcess){
        return <h3>Something went wrong: {response}</h3>
    }
    else if(!status.inProcess){
        return <NewAdvertiserForm submitAdvertiser={(entry) => submitAdvertiser(entry)}/>
    }else{
        return(
            <div className="submit-screen">
                <Spinner style={{ width: '20rem', height: '20rem' }} type="grow" />
            </div>
        );
    }
         
}


export default withAuthProvider(withRouter(NewAdvertiserFormContainer));