import React, {useState} from 'react';
import {FormGroup, Label, Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import './../newSaleForm/newSale.styles.scss';

const UpdateAdForm = ({sizes, order, setOrder, closeModal, submitUpdate}) => {
    
    const handleChange = e => {
        const { name } = e.target;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setOrder({[name]: value});
    }

    const [modal, setModal] = useState(true);

    const toggle = () => {
        setModal(!modal);
        closeModal();
    }

    const confirm = () => {
        submitUpdate();
        setModal(!modal);
        closeModal();
    }

    return(
        <Modal className="form-modal" isOpen={modal} toggle={toggle}>

            <ModalHeader>
                Update Ad Details
            </ModalHeader>

            <ModalBody>
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label for="kind">Ad Type</Label>
                            <Input disabled type="select" name="type" value={order.type}>
                                <option value="none" hidden> Choose type of ad...</option>
                                <option value="print">Print</option>
                                <option value="web">Web</option>
                                <option value="other">Other</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>  
                            <Label for="adSize">Size</Label>
                            <Input onChange={handleChange} type="select" name="size" defaultValue={order.size}>
                            <option value="none" hidden> Choose ad size...</option>
                            {
                                sizes[order.type].map((size) => {
                                    return <option key={size} value={size}>{size}</option>
                                })
                            }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="run_status">Run Status</Label>
                            <Input onChange={handleChange} type="select" name="run_status" defaultValue={order.run_status}>
                                <option value="scheduled">Scheduled</option>
                                <option value="run">Run</option>
                                <option value="tentative">Tentative</option>
                                <option value="hold">Hold</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                    
                <FormGroup check>
                    <Label check>
                        <Input onChange={handleChange} type="checkbox" name="premium" defaultChecked={order.premium}/>
                        Premium Placement?
                    </Label>
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <Button onClick={toggle}>Cancel</Button>
                <Button color="primary" onClick={() => confirm()}>Update Ad</Button>
            </ModalFooter>

        </Modal>
    );
}


export default UpdateAdForm;