import React, {useState} from 'react';
import ShowNotes from './../../components/notes/ShowNotes.component';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

const InfoModal = (props) => {
    const {
      closeModal,
      ad
    } = props;
  
    const [modal, setModal] = useState(true);
  
    const toggle = () => {
        setModal(!modal);
        closeModal();
    }

    return (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{ad.title} - {ad.date.toDateString()}</ModalHeader>
          <ModalBody>
            <p><b>{ad.title}</b> has purchased a dedicated email to run on <b>{ad.date.toDateString()}</b>.</p>
            <h6>Notes about this Sale:</h6>
            <ShowNotes notes={ad.notes}/>
            {ad.notes.length < 1 ? <p>No Notes for this Sale.</p> : null}
         </ModalBody>
        </Modal>
    );
  }
  
  export default InfoModal;