import React, { useEffect, useState } from 'react';
import {Container} from 'reactstrap';
import axios from 'axios'; 
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import InfoModal from './infoModal';
import './dedicated.styles.scss';

const DedicatedEmails = () => {

    const [calendar, setCalendar] = useState();
    const [adSelected, setAdSelected] = useState();

    useEffect(() => {
        axios.get("/public/ads/dedicatedEmails")
        .then(res => {
            const events = res.data.map(ad => {
                return({
                    title: ad.company,
                    date: new Date(ad.date),
                    extendedProps: {
                        notes: ad.notes,
                        date: new Date(ad.date)
                    }
                })
            })
            setCalendar(events);
        })
    }, []);

    const handleEventClick = (info) => {
        setAdSelected({title: info.title, date: info.extendedProps.date, notes: info.extendedProps.notes})
    }

    return(
        <div>
            <h6 style={{padding: "50px 0 30px"}}>The Living Church Upcoming Dedicated Emails Calendar</h6>
            <div style={{textAlign: "center"}}>
                <img src="https://livingchurch.org/wp-content/uploads/2020/03/lamb-logo-small-02-03-23-20.png"
                    alt="Intended for use by The Living Church Foundation"></img>
            </div>
            <Container>
                <FullCalendar
                    plugins={[ dayGridPlugin ]}
                    eventClick={(info) => handleEventClick(info.event._def)}
                    initialView="dayGridMonth"
                    weekends={true}
                    events={calendar}
                    defaultAllDay
                />
            </Container>
            {adSelected ? <InfoModal ad={adSelected} closeModal={() => setAdSelected(null)}/> : null} 
        </div>
    );
    
}

export default DedicatedEmails;