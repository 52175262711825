import React, {useState, useEffect} from 'react';
import { ListGroup, ListGroupItem, Badge, Spinner, 
    Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import './adsList.styles.scss';
import withAuthProvider from './../../AuthProvider';

const AdsList = ({ history, status, query, axios }) => {
    
    const [ads, setAds] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);

    //update info from api on mount and on updates
    useEffect(() => {
        axios.get(`/api/ads/list/${status}/1/${query.search}/${query.adType}/${query.dateType}/${query.date1}/${query.date2}/${query.hasFiles}`)
            .then(res => {
                const adsData = res.data.list.map(({name, adId, type, currentState, message, statusColor, fileMessage, fileStatusColor}) => {
                    return {name, adId, type, currentState, message, statusColor, fileMessage, fileStatusColor}    
                })
                const total = parseInt(res.data.pageCount);
                setPages(buildNav(total, 1));
                setPageCount(total);
                setAds(adsData);
                setIsFetching(false);
                setCurrentPage(1);
            })
    }, [query, status]); // eslint-disable-line

    const buildNav = (pageCount, page) => {
        const nav = [];

        if(page > 3){
            nav[0] = page-2;
            nav[1] = page-1;
            nav[2] = page;
            if (page+1 <= pageCount){nav[3] = page+1;}
            if (page+2 <= pageCount){nav[4] = page+2;}
        }else{
            nav[0] = 1;
            if (2 <= pageCount){nav[1] = 2;}
            if (3 <= pageCount){nav[2] = 3;}
            if (4 <= pageCount){nav[3] = 4;}
            if (5 <= pageCount){nav[4] = 5;}
        }
    return nav;
    }

    const handlePageChange = page => {
        setIsFetching(true);
        axios.get(`/api/ads/list/${status}/${page}/${query.search}/${query.adType}/${query.dateType}/${query.date1}/${query.date2}/${query.hasFiles}`)
            .then(res => {
                const adsData = res.data.list.map(({name, adId, type, currentState, message, statusColor, fileMessage, fileStatusColor}) => {
                    return {name, adId, type, currentState, message, statusColor, fileMessage, fileStatusColor}    
                })
                setPages(buildNav(pageCount, page));
                setAds(adsData);
                setIsFetching(false);
                setCurrentPage(page);
            })
    }

    return(
        <div>
            <div className="ads-list-container">
                <ListGroup className="ads-list">
                { isFetching ? <Spinner/> : ads.length < 1 ? <p>No Results Found.</p> : null}
                {ads.map(ad => {
                    return(
                        <ListGroupItem 
                        action
                        onClick={() => history.push(`/app/ads/manage/${ad.adId}`)}   
                        tag="button" 
                        key={ad.adId}
                        >
                            <div className="ad-list-item">
                                <span>{ad.name} <Badge>{ad.type}</Badge> <Badge color={ad.fileStatusColor}>{ad.fileMessage}</Badge></span>
                                <span>{ad.message} <Badge color={ad.statusColor}>{ad.currentState}</Badge></span>
                            </div>
                        </ListGroupItem>
                    );
                })}
                </ListGroup>
            </div>
            <Pagination aria-label="Ad Page Navigation">
            <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink first onClick={() => handlePageChange(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1 ? true : undefined}>
                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
              </PaginationItem>
              {
                  pages.map(page => {
                      return(
                          <PaginationItem key={page} active={page === currentPage ? true : undefined}>
                              <PaginationLink 
                              onClick={() => handlePageChange(page)}
                              >
                              {page}
                              </PaginationLink>
                          </PaginationItem>
                      );
                  })
              }
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
              </PaginationItem>
              <PaginationItem disabled={currentPage === pageCount ? true : undefined}>
                <PaginationLink last onClick={() => handlePageChange(pageCount)} />
              </PaginationItem>
            </Pagination>
        </div>
    );
}

export default withAuthProvider(withRouter(AdsList));

