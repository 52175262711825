import dataActionTypes from './data.actionTypes';

const INITIAL_STATE = {
    salesReps: null,
    advertisers: null,
    sizes: null,
    message: null,
}

const dataReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
    //responses for getting sales reps list
        case dataActionTypes.RETRIEVE_REPS_START:
            return{
                ...state,
                message: 'fetching sales reps'
            };
        case dataActionTypes.RETRIEVE_REPS_SUCCESS:
            return{
                ...state,
                salesReps: action.payload,
                message: null
            };
        case dataActionTypes.RETRIEVE_REPS_FAILURE:
            return{
                ...state,
                message: action.payload
            };

    //responses for getting size options         
        case dataActionTypes.RETRIEVE_SIZES_START:
            return{
                ...state,
                message: 'fetching list of size options'
            };
        case dataActionTypes.RETRIEVE_SIZES_SUCCESS:
            return{
                ...state,
                sizes: action.payload,
                message: null
            };
        case dataActionTypes.RETRIEVE_SIZES_FAILURE:
            return{
                ...state,
                message: action.payload
            };
            
    //responses for getting list of advertisers         
        case dataActionTypes.RETRIEVE_ADVERTISERS_START:
            return{
                ...state,
                message: 'fetching list of advertisers'
            };
        case dataActionTypes.RETRIEVE_ADVERTISERS_SUCCESS:
            return{
                ...state,
                advertisers: action.payload,
                message: null
            };
        case dataActionTypes.RETRIEVE_ADVERTISERS_FAILURE:
            return{
                ...state,
                message: action.payload
            };  
        default: 
            return state;
    }
}

export default dataReducer;