import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, 
    Label, Input, Row, Col, ModalHeader, InputGroup, InputGroupAddon } from 'reactstrap';

const AddInvoiceModal = (props) => {

    const {
        submitNewInvoice,
        cancelInvoice
      } = props;

    const [modal, setModal] = useState(true);
    const [submission, setSubmission] = useState({terms: "NET 30", send_on: "", price: 0, notesArray: [], status: "To Be Sent"});
    const {terms, send_on, price, notes} = submission;

    const toggle = () => {
        setModal(!modal);
        cancelInvoice();
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setSubmission({...submission, [name]: value})
    }

    const handleNotesChange = e => {
        const { value } = e.target;
        let notes = [];

        if(value !== ""){notes[1] = value;}
        
        setSubmission({...submission, notesArray: notes})
    }

    const handleSubmit = () => {
        //validate that neccasary fields filled out
        if(send_on === ""){
            alert("You have not selected a send date.")
        }else if(price === 0){
            alert("You have not selected a price.")
        }else{
            submitNewInvoice(submission);
            setModal(!modal);     
        }
    }

return (
    <div>
      <Modal className="form-modal" isOpen={modal} toggle={toggle}>

        <ModalHeader>
        Add New Invoice
        </ModalHeader>

        <ModalBody>
            <Row form>
                <Col>
                    <FormGroup>
                        <Label for="terms">Terms of Sale</Label>
                        <Input onChange={handleChange} type="select" name="terms" id="terms" value={terms}>
                            <option>NET 30</option>
                            <option>NET 45</option>
                            <option>NET 60</option>
                            <option>Due upon receipt</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="send_on">Send On</Label>
                        <Input
                        type="date"
                        onChange={handleChange}
                        value={send_on}
                        name="send_on"
                        id="send_on"
                        placeholder="Invoice to be sent on" 
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col>
                    <FormGroup>
                        <Label for="price">Amount Due</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                            <Input
                                onChange={handleChange}
                                value={price} 
                                type="number" 
                                name="price"
                                id="price" 
                                step="100"/>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col>
                    <FormGroup>
                        <Label for="notes">Notes</Label>
                        <Input
                            onChange={handleNotesChange}
                            value={notes} 
                            type="textarea" 
                            name="notes"
                        />
                    </FormGroup>
                </Col>
            </Row>
        </ModalBody>
        
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={() => handleSubmit()}>Add New Invoice</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddInvoiceModal;
