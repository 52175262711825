import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';

const NewAdvertiserForm = ({submitAdvertiser}) => {

    const [entry, setEntry] = useState();
    
    const handleSubmit = e => {
        e.preventDefault();

        //validate as necceasry here

        submitAdvertiser(entry)
    }

    const handleChange = e => {
        const { value, name, id } = e.target;

        if(name === "address"){
            setEntry({...entry, address: {...entry.address, [id]: value}});
        }else{
            setEntry({...entry, [name]: value});
        }
    }

    return(
        <div>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="company_name">Company</Label>
                    <Input onChange={handleChange} type="text" name="company_name" id="company_name" placeholder="Enter Company Name" required/>
                </FormGroup>
                <FormGroup>
                    <Label for="account_number">Account Number (If Available)</Label>
                    <Input onChange={handleChange} type="text" name="account_number" id="account_number" placeholder="Enter Corresponding Account Number from Billing Source" />
                </FormGroup>

                <hr/>

                <FormGroup>
                    <Label for="address1">Address</Label>
                    <Input onChange={handleChange} type="text" name="address" id="address1" placeholder="1234 Main St" required/>
                </FormGroup>
                <FormGroup>
                    <Label for="address2">Address 2</Label>
                    <Input onChange={handleChange} type="text" name="address" id="address2" placeholder="Apartment, studio, floor..." />
                </FormGroup>
                <Row form>
                    <Col md={6}>
                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input onChange={handleChange} type="text" name="address" id="city" required/>
                    </FormGroup>
                    </Col>
                    <Col md={4}>
                    <FormGroup>
                        <Label for="state">State</Label>
                        <Input onChange={handleChange} type="text" name="address" id="state" required/>
                    </FormGroup>
                    </Col>
                    <Col md={2}>
                    <FormGroup>
                        <Label for="zip">Zip</Label>
                        <Input onChange={handleChange} type="text" name="address" id="zip" required/>
                    </FormGroup>  
                    </Col>
                </Row>
                    <FormGroup>
                        <Label for="country">Country</Label>
                        <Input onChange={handleChange} type="text" name="address" id="country" required/>
                    </FormGroup>  

                <hr/>

                <FormGroup>
                    <Label for="contact_person">Contact Person</Label>
                    <Input onChange={handleChange} type="text" name="contact_person" id="contact_person" placeholder="Name of Contact for this advertiser" required/>
                </FormGroup>
                <FormGroup>
                    <Label for="phone">Phone</Label>
                    <Input onChange={handleChange} type="text" name="phone" id="phone" placeholder="0 123 456 7890"/>
                </FormGroup>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input onChange={handleChange} type="email" name="email" id="email" placeholder="name@example.com"/>
                </FormGroup>

                <Button>Add New Advertiser</Button>
            </Form>
        </div>
    );
}

export default NewAdvertiserForm;
