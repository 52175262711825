import React, {useState} from 'react';
import { ListGroup, ListGroupItem, Badge, Button, Nav, NavLink, NavItem} from 'reactstrap';
import UpdateInvoiceForm from './updateInvoice.component';
import ShowNotes from './../notes/ShowNotes.component';
import { withRouter } from 'react-router-dom';
import './invoice.styles.scss';

const Invoice = ({ invoice, history, updateInvoice, uploadFile, files, addNote, removeInvoice }) => {

    const [updates, setUpdates] = useState({isEmpty: true});
    const [updateModal, setUpdateModal] = useState(false);

    const {
        status, terms, advertiser, sale, notes, price, short_name, company_name, 
        part_of_sale, invoice_no, send_on, sent_on, paid_on, description
    } = invoice;

    const handleUpdateSubmit = () => {
        updateInvoice(updates);
        setUpdates({isEmpty: true});
    }

    const getStatusDates = () => {
        //get status dates in Date format
        const statusDates = [];

        if(send_on){
            let sendOn = new Date(send_on);
            statusDates.push(
                <p key="sendOn">Send On Date: <b>{sendOn.toDateString()}</b></p>
            );
        }
        if(sent_on){
            let sentOn = new Date(sent_on);
            statusDates.push(
                <p key="sentOn">Invoice Sent On: <b>{sentOn.toDateString()}</b></p>
            );
        }
        if(paid_on){
            let paidOn = new Date(paid_on);
            statusDates.push(
                <p key="paidOn">Invoice Paid On: <b>{paidOn.toDateString()}</b></p>
            );
        }

        //return information based on presensce of dates
        return statusDates;
    }

    const stageUpdate = update => {
        setUpdates({...updates, ...update, isEmpty: false})
    }

    return(
        <div className="single-invoice">
        
            {updateModal ? 
                <UpdateInvoiceForm
                    order={invoice}
                    setOrder={(update) => stageUpdate(update)}
                    submitUpdate={() => {handleUpdateSubmit()}}
                    closeModal={() => setUpdateModal(false)}
                /> 
            : null}

            <div className="invoice-actions">
                <Nav>
                    <NavItem>
                        <NavLink onClick={() => addNote()}>New Note</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setUpdateModal(true)}>Update Invoice</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => uploadFile()}>Upload File</NavLink>
                    </NavItem>
                </Nav>
                <Nav className="invoice-actions">
                    <NavItem>
                        <NavLink className="delete-button" onClick={() => removeInvoice()}>Delete This Invoice</NavLink>
                    </NavItem>
                </Nav>
            </div>
            <hr style={{backgroundColor: "#1e7e34"}}/>
            
            <div className="single-invoice-title">
                <h6 style={{padding: "0", color: "#1e7e34"}}>Showing Invoice</h6>
                <h3>{short_name}</h3>
            </div>
                     
        <div className="invoice-info">
            <div className="basic-invoice-info">
                <h6>Invoice Details</h6>
                <p>Amount Due: <b>${price}</b></p>
                <p>Invoice Number: <b>{invoice_no ? invoice_no : <sub>No Invoice Number on File</sub>}</b></p>
                <p>Terms of Sale: <b>{terms}</b></p>
                <p>Part of Sale: <b>{part_of_sale}</b></p>
            </div>
            <div className="basic-invoice-info">
                <h6>Current Status</h6>
                <p>Status: <b>{status}</b></p>
                {getStatusDates()}
                
            </div>
            <div className="basic-invoice-info">
                <h6>Suggested Invoice Description</h6> 
                <b>"{description}"</b>
                <hr/>
                <h6>Quick Links</h6>
                <NavLink style={{textAlign: 'center'}} onClick={() => {history.push(`/app/advertisers/manage/${advertiser}`)}}>Go To {company_name}</NavLink>
                <NavLink style={{textAlign: 'center'}} onClick={() => {history.push(`/app/sales/manage/${sale}`)}}>Go To Sale</NavLink>
            </div>
        </div>

        <div className="invoice-content">
            <div className="invoice-content-left">
                <h6>Notes</h6>
                <ShowNotes notes={notes}/>
                <Button className="add-note" size="sm" color="primary" onClick={() => addNote()}>+</Button>
            </div>
            <div className="invoice-content-right">
                <h6>Files</h6>
                <ListGroup>
                {files.map(file => {
                    return(
                        <ListGroupItem key={file.name} className="file-list-item">
                            <span>
                                {file.name} <Badge pill>{file.size}</Badge>
                            </span>
                            <span>
                                <Button size="sm" className="file-buttons" target="_blank" href={file.viewUrl}>View File</Button>
                                <Button size="sm" className="file-buttons" href={file.downloadUrl}>Download File</Button>
                            </span>
                        </ListGroupItem>
                    ); 
                })}
                </ListGroup>
                <Button className="add-note" size="sm" onClick={() => uploadFile()}>+</Button>
            </div>
        </div>
    </div>
    );
}

export default withRouter(Invoice);

