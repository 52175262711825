import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Input, CustomInput } from 'reactstrap';

const AddFileModal = ({uploadFile, ads, closeModal}) => {

    const [modal, setModal] = useState(true);
    const [file, setFile] = useState();
    const [adsSelected, setAdsSelected] = useState([]);

    const toggle = () => {
        setModal(!modal);
        closeModal();
    }
    const handleUpload = () => {
        uploadFile(file, adsSelected);
    }

    const handleAdSelect = e => {
        const {value} = e.target;

        if(adsSelected.includes(value)){
            setAdsSelected(adsSelected.filter(ad => ad !== value));
        }else{
            setAdsSelected([...adsSelected, value]);
        }
    }

    const handleFileChange = e => {
        const {files} = e.target;
        setFile(files[0]);
    }

  return (
    <div>
      <Modal className="form-modal" isOpen={modal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
        toggle={toggle}>

        <ModalBody>
        <h3>Select Ads for this file</h3>
        <hr/>
        {
            ads.map(ad => {
                return(
                    <FormGroup key={ad.adId} check>
                        <Label check>
                            <Input onChange={handleAdSelect} value={ad.adId} type="checkbox" /> {ad.size} - {ad.type}
                        </Label>
                    </FormGroup>
                );
            })
        }
        <hr/>

        <FormGroup>
            <Label for="adFile">Select File</Label>
            <CustomInput onChange={handleFileChange} type="file" id="adFile" name="AdFile" />
        </FormGroup>


        </ModalBody>
        
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={() => handleUpload()}>Upload</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddFileModal;