import React, { useState, useEffect } from 'react';
import Crumbs from './../../components/crumbs.component';
import { FormGroup, Label, Input, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import withAuthProvider from './../../AuthProvider';
import classnames from 'classnames';
import "./setting.styles.scss";

const SettingsPage = ({ axios }) => {

    const [reps, setReps] = useState([])
    const [sizes, setSizes] = useState([])
    const [newRep, setNewRep] = useState("");
    const [toRemoveRep, setToRemoveRep] = useState("none");
    const [toRemoveSize, setToRemoveSize] = useState("none");
    const [newSize, setNewSize] = useState({kind: false, size: ""});
    const [issue, setIssue] = useState({issue_date: "", issue_name: ""});
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        axios.get("/api/salesreps")
        .then(res => {setReps(res.data)})
        axios.get("/api/sizes")
        .then(res => {setSizes(res.data)})
    }, []); // eslint-disable-line

    const newRepChange = e => {
        const {value} = e.target;
        setNewRep(value);
    }

    const submitNewRep = () => {
        if(newRep !== ""){
            var answer = window.confirm(`Confirm ${newRep}?`)
            if(answer){
                axios.post("/api/salesreps", {name: newRep})
                .then(res => { 
                    alert(`${res.data.name} has been added.`); 
                    setNewRep("");
                axios.get("/api/salesreps")
                .then(res => {setReps(res.data)})})
                .catch(err => alert(err.message))
            }
        }else{
            alert("No Name has been entered.")
        } 
    }

    const toRemoveRepChange = e => {
        const {value} = e.target;
        setToRemoveRep(value);
    }

    const removeRep = () => {
        if(toRemoveRep !== "none"){
            var answer = window.confirm(`Are you sure you want to remove this Sales Representative?`)
            if(answer){
                axios.delete(`/api/salesreps/${toRemoveRep}`)
                .then(res => {
                    setToRemoveRep("none");
                    setReps(reps.filter(({_id}) => _id !== toRemoveRep));
                    alert(`${res.data} has been removed.`);
                })
                .catch(err => alert(err.message))
            }
        }else{
            alert("No Representative has been selected for removal.")
        }  
    }

    const toRemoveSizeChange = e => {
        const {value} = e.target;
        setToRemoveSize(value);
    }

    const removeSize = () => {
        if(toRemoveSize!== "none"){
            var answer = window.confirm(`Are you sure you want to remove this Ad Size?`)
            if(answer){
                axios.delete(`/api/sizes/${toRemoveSize}`)
                .then(res => {
                    setToRemoveSize("none");
                    setSizes(sizes.filter(({_id}) => _id !== toRemoveSize));
                    alert(`${res.data.kind} - ${res.data.size} has been removed.`);
                })
                .catch(err => alert(err.message))
            }
        }else{
            alert("No Ad Size has been selected for removal.")
        }  
    }

    const sizeChange = e => {
        const {value, name} = e.target;
        setNewSize({...newSize, [name]: value});
    }

    const submitNewSize = () => {
        if(newSize.kind !== false && newSize.size !== ""){
            var answer = window.confirm(`Confirm New Ad Size ${newSize.kind} - ${newSize.size}?`)
            if(answer){
                axios.post("/api/sizes", newSize)
                .then(res => {
                    alert(`${res.data.kind} - ${res.data.size} has been added.`)
                    setNewSize({kind: false, size: ""});
                })
                .catch(err => alert(err.message))
            } 
        }else{
            alert("New Ad size has not been defined.")
        }
    }

    const issueChange = e => {
        const {value, name} = e.target;
        setIssue({...issue, [name]: value});
    }

    const submitIssue = () => {
        if(issue.issue_date !== "" && issue.issue_name !== ""){
            var answer = window.confirm(`Confirm New Issue "${issue.issue_name}" to run on ${issue.issue_date}?`)
            if(answer){
                axios.post("/api/issues", issue)
                .then(res => {
                    alert(`${res.data.issue_name} issue has been added.`)
                    setIssue({issue_date: "", issue_name: ""});
                })
                .catch(err => alert(err.message))
            } 
        }else{
            alert("New Issue has not been defined.")
        }
    }
        

    return(
        <div>
            <Crumbs page="Settings"/>
            <div className="page-content-wrapping">
                <h6 className="settings-title">Settings</h6>

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Issues
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Sales Representatives
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                            Sizes
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="settings-action">
                            <h5>Add Issue</h5>
                            <FormGroup>
                                <Label for="issueDate">Issue Date</Label>
                                <Input onChange={issueChange} type="date" name="issue_date" id="issueDate" value={issue.issue_date}/>
            
                                <Label for="issueName">Issue name</Label>
                                <Input onChange={issueChange} type="text" name="issue_name" id="issueName" value={issue.issue_name}/>
                            </FormGroup>
                            <Button color="primary" onClick={() => submitIssue()}>Add New Issue</Button>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="settings-action">
                            <FormGroup>
                            <Label for="salesRep"><h5>Add New Sales Representative</h5></Label>
                            <Input onChange={newRepChange} type="text" name="salesRep" id="salesRep" value={newRep}/>
                            </FormGroup>
                            <Button onClick={() => submitNewRep()}>Add Representative</Button>
                        </div>
                        
                        <hr/>

                        <div className="settings-action">
                            <FormGroup>
                                <Label for="removeRep"><h5>Remove Sales Representative</h5></Label>
                                <Input onChange={toRemoveRepChange} type="select" name="removeRep" id="removeRep" value={toRemoveRep}>
                                    <option value="none" hidden> Choose Sales Representative to Remove...</option>
                                {
                                    reps.map(({name, _id}) => {
                                        return <option key={name} value={_id}>{name}</option>
                                    })
                                }
                                </Input>
                            </FormGroup>
                            <Button color="danger" onClick={() => removeRep()}>Remove Representative</Button>
                        </div>
                    </TabPane>

                    <TabPane tabId="3">
                        <div className="settings-action">
                            <h5>Add New Ad Size</h5>
                            <FormGroup>
                                <Label for="kind">Ad Type</Label>
                                <Input onChange={sizeChange} type="select" name="kind" value={newSize.kind}>
                                    <option value={false} hidden> Choose type of ad...</option>
                                    <option value="print">Print</option>
                                    <option value="web">Web</option>
                                    <option value="other">Other</option>
                                </Input>
                            </FormGroup>
            
                            <FormGroup>
                                <Label for="size">Size</Label>
                                <Input onChange={sizeChange} value={newSize.size} type="text" name="size" id="size" disabled={!newSize.kind}/>
                            </FormGroup>
                            <Button onClick={() => submitNewSize()}>Add Ad Size</Button>
                        </div>

                        <hr/>

                        <div className="settings-action">
                            <h5>Remove Ad Size</h5>
                            <FormGroup>
                                <Label for="removeSize">Remove Ad Size</Label>
                                <Input onChange={toRemoveSizeChange} type="select" name="removeSize" id="removeSize" value={toRemoveSize}>
                                    <option value="none" hidden> Choose Ad Size to Remove...</option>
                                {
                                    sizes.map(({kind, _id, size}, index) => {
                                        return <option key={`${index}adsize`} value={_id}>{size} ({kind})</option>
                                    })
                                }
                                </Input>
                            </FormGroup>
                            <Button color="danger" onClick={() => removeSize()}>Remove Ad Size</Button>
                        </div>
                    </TabPane>
              </TabContent>   
            </div>
        </div>    
    );
}

export default withAuthProvider(SettingsPage);