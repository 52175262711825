import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner, Alert } from 'reactstrap';
import AppNavbar from './components/AppNavbar.component';
import AdsPage from './pages/ads/ads.page';
import SalesPage from './pages/sales/sales.page';
import NewSalePage from './pages/sales/newSale/newSale.page';
import NewAdvertiserPage from './pages/advertisers/newAdvertiser/AddAdvertiser.page';
import ManageSalePage from './pages/sales/manageSale/manageSale.page';
import ReportsPage from './pages/reports/reports.page';
import SettingsPage from './pages/settings/settings.page';
import AdvertisersPage from './pages/advertisers/advertisers.page';
import ManageAdvertiserPage from './pages/advertisers/manageAdvertiser/manageAdvertiser.page';
import Dashboard from './pages/dashboard/dashboard.page';
import InvoicesPage from './pages/invoices/invoices.page';
import ManageInvoicePage from './pages/invoices/manageInvoice/manageInvoice.page';
import ManageAdPage from './pages/ads/manageAd/ManageAd.page';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import withAuthProvider from './AuthProvider';
import SignIn from'./pages/signin/signin.component';

class App extends React.Component {

  render() {
    let error = null;
    if (this.props.error) {
      error = <Alert color="danger">{this.props.error.message}</Alert>;
    }

  if (this.props.loaded){
    return (
      <Provider store={store}>
        <div className="App">
          <AppNavbar 
          isAuthenticated={this.props.isAuthenticated}
          authButtonMethod={this.props.isAuthenticated ? this.props.logout : this.props.login}
          user={this.props.user}/>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/app"/>}/>
            { this.props.isAuthenticated ? 
              <Route exact path="/signin" render={() => <Redirect to="/app"/>}/> : 
              <Route path="/app" render={() => <SignIn signInButton={this.props.login}/>}/> 
            }
            <Route exact path="/signin" render={() => <SignIn signInButton={this.props.login}/>}/>
            <Route exact path="/app" component={Dashboard}/>
            <Route exact path="/app/ads/nextissue" render={() => <AdsPage nextIssue/>}/>
            <Route exact path="/app/ads/dedicated" render={() => <AdsPage dedicated/>}/>
            <Route exact path="/app/ads/nextissue/noresource" render={() => <AdsPage nextIssue noResource/>}/>
            <Route exact path="/app/ads" component={AdsPage}/>
            <Route path="/app/ads/manage/:adId" component={ManageAdPage}/>
            <Route exact path="/app/invoices" component={InvoicesPage}/>
            <Route exact path="/app/invoices/overdue" render={() => <InvoicesPage overdue/>}/>
            <Route exact path="/app/invoices/send" render={() => <InvoicesPage send/>}/>
            <Route exact path="/app/invoices/sendsoon" render={() => <InvoicesPage sendsoon/>}/>
            <Route exact path="/app/invoices/sent" render={() => <InvoicesPage sent/>}/>
            <Route path="/app/invoices/manage/:invoiceId" component={ManageInvoicePage}/>
            <Route exact path="/app/sales" component={SalesPage}/>
            <Route path="/app/sales/page/:page" component={SalesPage}/>
            <Route exact path="/app/sales/add/" component={NewSalePage}/>
            <Route path="/app/sales/add/organization/:organization" component={NewSalePage}/>
            <Route path="/app/sales/manage/:saleId" component={ManageSalePage}/>
            <Route path="/app/reports" component={ReportsPage}/>
            <Route path="/app/settings" component={SettingsPage}/>
            <Route exact path="/app/advertisers" component={AdvertisersPage}/>
            <Route exact path="/app/advertisers/add" component={NewAdvertiserPage}/>
            <Route path="/app/advertisers/manage/:id" component={ManageAdvertiserPage}/>
          </Switch>
          {error}
          <div style={{textAlign: "center", padding: "50px"}}>
            <img src="https://livingchurch.org/wp-content/uploads/2020/03/lamb-logo-small-02-03-23-20.png" 
              alt="Intended for use by The Living Church Foundation"></img>
          </div>
        </div>
      </Provider>
    );
  } else {
    return <Spinner/>
  } 
}
}

export default withAuthProvider(App);



