import React, {useState} from 'react';
import {FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import './newSale.styles.scss';
import AdFrequency from './adFrequency.component';

const AdInputs = ({sizes, order, setOrder}) => {
    
    const [sizeSelected, setSizeSelected] = useState([{type: "none", kind: [], empty: true}]);

    const addAdInputRow = () => {
        const newSizeSelected = [...sizeSelected];
        newSizeSelected.push({type: "none", kind: [], empty: true});
        setSizeSelected(newSizeSelected);

        let newOrder = [...order];
        newOrder.push({
            notes: "", 
            premium: false, 
            run_status: "scheduled"
        });
        setOrder(newOrder);
    }

    const removeAdInputRow = () => {
        const newSizeSelected = [...sizeSelected];
        const newOrders = [...order];
        newSizeSelected.pop();
        newOrders.pop();

        setSizeSelected(newSizeSelected);
        setOrder(newOrders);
    }

    const addIssue = index => {
        const newIssues = [...sizeSelected[index].issues];
        newIssues.push("");

        const newSizeSelected = [...sizeSelected];
        newSizeSelected[index] = {...sizeSelected[index], issues: newIssues}
        setSizeSelected(newSizeSelected);
    }

    const removeIssue = index => {
        const newIssues = [...sizeSelected[index].issues];
        newIssues.pop();

        const newSizeSelected = [...sizeSelected];
        newSizeSelected[index] = {...sizeSelected[index], issues: newIssues}
        setSizeSelected(newSizeSelected);

        const newOrder = [...order];
        newOrder[index] = {...order[index], run_on: newIssues};
        setOrder(newOrder);
    }

    const handleAdType = e => {
        const { value, id, name } = e.target;
        const tempSizeSelected = [...sizeSelected]; 
        if(value === "web"){
            tempSizeSelected[id] = {type: value, kind: sizes.web, empty: false}
        }else if(value === "print"){
            tempSizeSelected[id] = {type: value, kind: sizes.print, empty: false}
        }else if(value === "other"){
            tempSizeSelected[id] = {type: value, kind: sizes.other, empty: false}
        }else{
            tempSizeSelected[id] = {type: value, kind: [], empty: true}
        }
        setSizeSelected(tempSizeSelected);
        let newOrder = [...order];
        newOrder[id] = {
            [name]: value, 
            size: "none", 
            notes: newOrder[id].notes, 
            premium: newOrder[id].premium, 
            run_status: newOrder[id].run_status
        };
        setOrder(newOrder);
    }

    const handleChange = e => {
        const { id, name } = e.target;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        let newOrder = [...order];
        if(name === "run_on"){
            newOrder[id] = {...order[id], [name]: [value]};
        }else{
            newOrder[id] = {...order[id], [name]: value};
        }
        setOrder(newOrder);
    }

    const handleFrequencyChange = e => {
        const { id, value, name } = e.target;
        const newSizeSelected = [...sizeSelected];
        newSizeSelected[id] = {...sizeSelected[id], frequency: value, issues: [0]}
        setSizeSelected(newSizeSelected);
        
        let newOrder = [...order];
        newOrder[id] = {
            [name]: value,
            type: newOrder[id].type, 
            size: newOrder[id].size,
            notes: newOrder[id].notes, 
            premium: newOrder[id].premium, 
            run_status: newOrder[id].run_status
        };

        //keep run_on value if switching between single and multiple-by-time
        if((order[id].frequency === "single" || 
            order[id].frequency === "multiple-by-time") && 
            (value === "single" || value === "multiple-by-time"
            )
        ){ newOrder[id] = {...newOrder[id], run_on: order[id].run_on};}
        setOrder(newOrder);
    }

    const handleIssueChange = index => e => {
        const { id, value } = e.target;

        const newIssues = [...sizeSelected[index].issues];
        newIssues[id] = value;

        const newSizeSelected = [...sizeSelected];
        newSizeSelected[index] = {...sizeSelected[index], issues: newIssues}
        setSizeSelected(newSizeSelected);

        let newOrder = [...order];
        newOrder[index] = {...order[index], run_on: newIssues};
        setOrder(newOrder);
    }

    return(
        <div>
            {sizeSelected.map(({kind, empty},index) => {
                return(
                    <div key={`ad${index}`} className="ad-purchase">
                        <h5>Purchase #{index+1}</h5>
            
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="kind">Ad Type</Label>
                                    <Input onChange={handleAdType} type="select" name="type" id={index} defaultValue="none">
                                        <option value="none" hidden> Choose type of ad...</option>
                                        <option value="print">Print</option>
                                        <option value="web">Web</option>
                                        <option value="other">Other</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>  
                                    <Label for="adSize">Size</Label>
                                    <Input onChange={handleChange} type="select" name="size" id={index} disabled={empty} value={order[index].size}>
                                    <option value="none" hidden> Choose ad size...</option>
                                    {
                                        kind.map((size) => {
                                            return <option key={size} value={size}>{size}</option>
                                        })
                                    }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        {sizeSelected[index].type !== "none" ? <hr/> : null}

                        {sizeSelected[index].type === "print" ? 

                            <div>
                                <FormGroup>
                                    <Label for="frequency">Frequency</Label>
                                    <Input onChange={handleFrequencyChange} type="select" name="frequency" id={index} defaultValue="none">
                                        <option value="none" hidden> Choose Frequency...</option>    
                                        <option value="single">Single Issue</option>
                                        <option value="multiple-by-time">Multiple Issues - Time Period</option>
                                        <option value="multiple-by-selection">Multiple Issues - Custom Selections</option>
                                    </Input>
                                </FormGroup>
                                <AdFrequency 
                                    frequency={sizeSelected[index].frequency} 
                                    handleChange={handleChange} 
                                    index={index}
                                    issues={sizeSelected[index].issues}
                                    handleIssueChange={handleIssueChange}
                                    addIssue={addIssue}
                                    removeIssue={removeIssue}/>
                            </div>
                            
                        : sizeSelected[index].type !== "none" ? 
                                    <AdFrequency frequency="none" handleChange={handleChange} index={index}/>
                        : null}

                        <hr/>

                        <Row>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="run_status">Run Status</Label>
                                    <Input onChange={handleChange} type="select" name="run_status" id={index} defaultValue="scheduled">
                                        <option value="scheduled">Scheduled</option>
                                        <option value="run">Run</option>
                                        <option value="tentative">Tentative</option>
                                        <option value="hold">Hold</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                            
                        <FormGroup check>
                            <Label check>
                                <Input onChange={handleChange} type="checkbox" name="premium" id={index}/>
                                Premium Placement?
                            </Label>
                        </FormGroup>
                           
                        <FormGroup>
                            <Label for="notes">Notes</Label>
                            <Input onChange={handleChange} type="textarea" name="notes" id={index} />
                        </FormGroup>
                    </div>
                )
            })}
            <Button className="green-button" onClick={addAdInputRow}>Add Another Purchase</Button>
            {sizeSelected.length > 1 ? <Button className="red-button" onClick={removeAdInputRow}>Remove Purchase</Button> : null}
        </div>
    );
}

export default AdInputs;
        