import React from 'react';
import AdContainer from './../../../components/ad/ad.container';

const ManageAdPage = ({ match }) => {

    //get id of ad
    const adId = match.params.adId;

    return(
        <div>
            <AdContainer adId={adId} asPage/>
        </div>
    );  
    
}

export default ManageAdPage;