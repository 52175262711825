import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from "./../../redux/auth/auth.selectors";

const AddNoteModal = ({submitNote, close, user}) => {

  const [modal, setModal] = useState(true);
  const [noteContent, setNoteContent] = useState("");

  const toggle = () => {
      setModal(!modal);
      close();
  }

  const handleSubmit = () => {
    submitNote({note: noteContent, user: user.displayName});
    toggle();
  }

  const handleChange = e => {
      const { value } = e.target;
      setNoteContent(value);
  }

  return (
    <div>
      <Modal className="form-modal" isOpen={modal} toggle={toggle}>

        <ModalHeader>
        Add Note
        </ModalHeader>

        <ModalBody>
            <FormGroup>
                <Input onChange={handleChange} type="textarea" name="note" id="note" rows="5"/>
            </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" onClick={() => handleSubmit()}>Add Note</Button>
        </ModalFooter>

      </Modal>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectUser
});

export default connect(mapStateToProps)(AddNoteModal);