import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import withAuthProvider from './../../AuthProvider';
import Crumbs from './../../components/crumbs.component';
import { Label, Input, Button, Spinner, ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import csvDownload from 'json-to-csv-export'
import Updates from './../../components/Updates.component';
import "./reports.styles.scss";

const ReportsPage = ({axios, history}) => {

    const [range, setRange] = useState(0);
    const [name, setName] = useState("")
    const [report, setReport] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [startDate, setsetStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [customSelection, setCustomSelection] = useState(false);

    const handleStartChange = e => {
        const {value} = e.target;
        setsetStartDate(value);
    }

    const handleEndChange = e => {
        const {value} = e.target;
        setEndDate(value);
    }

    const handleChange = e => {
        const {value} = e.target;
        setRange(value);
        if(value === "7"){
            setCustomSelection(true);
        }else{
            setCustomSelection(false);
        }
    }

    const getStartDate = span => {
        let d = new Date();
        let today = d.getFullYear() + "/" + 
        ("0" + (d.getMonth() +1) ).slice(-2) + "/" + 
        ("0" + d.getDate()).slice(-2);
        let result;
        let end = new Date();

        if(span === "1"){
            let day = d.getDay(),
                diff = d.getDate() - day; 
            result = new Date(d.setDate(diff));
            setName(
                "Week Of " +
                result.getFullYear() + "/" + 
                ("0" + (result.getMonth() +1) ).slice(-2) + "/" + 
                ("0" + result.getDate()).slice(-2)
            );  
        }else if(span === "2"){
            let diff = d.getDate() - 14; 
            result = new Date(d.setDate(diff));
            setName(
                result.getFullYear() + "/" + 
                ("0" + (result.getMonth() +1) ).slice(-2) + "/" + 
                ("0" + result.getDate()).slice(-2) + " - " + today   
            );     
        }else if(span === "3"){
            result = new Date(d.getFullYear(), d.getMonth(), 1);
            setName(
                result.getFullYear() + "/" + 
                ("0" + (result.getMonth() +1) ).slice(-2) + "/" + 
                ("0" + result.getDate()).slice(-2) + " - " + today
            );
        }else if(span === "4"){
            let month = d.getMonth(),
                diff =  month - 3; 
            result = new Date(d.setMonth(diff));
            setName(
                result.getFullYear() + "/" + 
                ("0" + (result.getMonth() +1) ).slice(-2) + "/" + 
                ("0" + result.getDate()).slice(-2) + " - " + today
            );
        }else if(span === "5"){
            result = new Date(d.getFullYear(), 0, 1);
            setName(
                result.getFullYear() + "/" + 
                ("0" + (result.getMonth() +1) ).slice(-2) + "/" + 
                ("0" + result.getDate()).slice(-2) + " - " + today
            );
        }else if(span === "6"){
            result = new Date("2000-01-01");
            setName("All Time");
        }else if(span === "7"){
            result = new Date(`${startDate} GMT-0600`);
            end = new Date(`${endDate} GMT-0600`);
            setName(
                result.getFullYear() + "/" + 
                ("0" + (result.getMonth() +1) ).slice(-2) + "/" + 
                ("0" + result.getDate()).slice(-2) + " - " + 
                end.getFullYear() + "/" + 
                ("0" + (end.getMonth() +1) ).slice(-2) + "/" + 
                ("0" + end.getDate()).slice(-2)
            );
        }

        setCustomSelection(false);
        result.setHours(0); result.setMinutes(0); result.setSeconds(0);
        return {start: result, end: end};
    }
 
    const getReport = () => {
        if(range !== 0 && range !== "7"){
            setIsFetching(true);

            //get date range
            let {start} = getStartDate(range);
            
            //call api
            axios.get(`/api/sales/reports/${start}/now`)
            .then(res => {
                setReport(res.data);

                //clear fields
                setRange(0);
                setIsFetching(false);
            })
        }else if(range === "7" && startDate !== 0 && endDate !== 0){
            //get date range
            let {start, end} = getStartDate(range);

            //call api
            axios.get(`/api/sales/reports/${start}/${end}`)
            .then(res => {
                setReport(res.data);

                //clear fields
                setRange(0);
                setIsFetching(false);
            })
        }else{
            alert("No Time Span has been selected for report.")
        }
    }

    const goToSale = (saleId) => {
        history.push(`/app/sales/manage/${saleId}`);
    }

    const downloadReport = () => {
        csvDownload(report, `${name}AdSalesReport.csv`)
    }

    return(
        <div>
            <Crumbs page="Reports"/>
            <div className="page-content-wrapping">
                <div>
                    <h6 className="reports-title">Updates</h6>
                    <Updates/>
                </div>

                <hr/>

                <h6 className="reports-title">Generate New Sales Report</h6>
                    <Row form>
                        <Col md={4}>
                            <Label>Select Time Span</Label>
                                <Input onChange={handleChange} type="select" value={range}>
                                    <option hidden value={0}>...Select Time Span</option>    
                                    <option value={1}>This Week</option>
                                    <option value={3}>This Month</option>
                                    <option value={5}>This Year</option>
                                    <option value={2}>Last 2 Weeks</option>
                                    <option value={4}>Last 3 Months</option>
                                    <option value={6}>All Time</option>
                                    <option value={7}>Custom Selection</option>
                                </Input>
                        </Col>
                        <Col className="form-button" md={2}>
                            <Button onClick={() => getReport()}>Generate Report</Button>
                        </Col>
                    </Row>
                    { customSelection ?
                        <Row form style={{padding: "10px 0"}}>
                            <Col md={2}>
                                <Label>Start Date</Label>
                                <Input onChange={handleStartChange} type="date" value={startDate}/>
                            </Col>
                            <Col md={2}>    
                                <Label>End Date</Label>
                                <Input onChange={handleEndChange} type="date" value={endDate}/>
                            </Col>
                        </Row> : null
                    }
                        <hr/>
                        {report.length > 0 ? 
                            <Row form>
                                <Col md={4}> Generated report for {name} </Col>
                                <Col className="form-button">
                                    <Button onClick={() => downloadReport()}>Download Report</Button> 
                                </Col>
                            </Row>
                        : null}
                    <div className="sale-list-container">
                    <ListGroup className="sales-list">
                    { isFetching ? <Spinner/> : 
                        report.length < 1 && name !== "" ? <p>No Results Found.</p> : 
                        report.length < 1 ? null : <h6 className="reports-title">All Sales Added {name}</h6>}
                    {
                        report.map(({name, saleAdded, saleId, totalAds, totalInvoices}) => {
                            return(
                                <ListGroupItem
                                    action
                                    className="reported-sale"
                                    tag="button"
                                    onClick={() => goToSale(saleId)}
                                    key={saleId}
                                >
                                    <div style={{width: '40%'}}>{name}</div>
                                    <div style={{width: '20%'}}>Ads: {totalAds}</div>
                                    <div style={{width: '20%'}}>Invoices: {totalInvoices}</div>
                                    <div style={{width: '20%'}}>
                                        <sub>Added on</sub>
                                        <span className="added-on-date"> {saleAdded}</span>
                                    </div>
                                </ListGroupItem>
                            );
                        })
                        
                    }
                    </ListGroup>
                </div>

                
            </div>
        </div>
    );
}

export default withAuthProvider(withRouter(ReportsPage));