import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { config } from './Config';
import { getUserDetails } from './GraphService';
import axios from 'axios';

export default function withAuthProvider(WrappedComponent) {
  return class extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        user: {},
        loaded: false
      };

      // Initialize the MSAL application object
      this.publicClientApplication = new PublicClientApplication({
        auth: {
            clientId: config.appId,
            authority: config.authority,
            redirectUri: config.redirectUri
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
      });

      this.axios = {
        get: async (path) => {
            const token = await this.getAccessToken(['api://9523c67c-63d0-4a6a-8354-6750e64abaa3/TLC.Read']);
            const verify = {
                headers: { 'access-token': token }
            }
            return new Promise((resolve, reject) => {
                axios.get(path, verify)
                .then(res => {resolve(res)})
                .catch(err => {reject(err)})
            })
        },
        post: async (path, body) => {
            const token = await this.getAccessToken(['api://9523c67c-63d0-4a6a-8354-6750e64abaa3/TLC.Read']);
            const verify = {
                headers: { 'access-token': token }
            }
            return new Promise((resolve, reject) => {
                axios.post(path, body, verify)
                .then(res => {resolve(res)})
                .catch(err => {reject(err)})
            })
        },
        delete: async (path) => {
          const token = await this.getAccessToken(['api://9523c67c-63d0-4a6a-8354-6750e64abaa3/TLC.Read']);
          const verify = {
              headers: { 'access-token': token }
          }
          return new Promise((resolve, reject) => {
              axios.delete(path, verify)
              .then(res => {resolve(res)})
              .catch(err => {reject(err)})
          })
      }
    };
    }

    componentDidMount() {
      // If MSAL already has an account, the user
      // is already logged in
      const accounts = this.publicClientApplication.getAllAccounts();

      if (accounts && accounts.length > 0) {
        // Enhance user object with data from Graph
        this.getUserProfile();
      }else{
          //set loaded to true if no signed in user
          this.setState({...this.state, loaded: true});
      }
    }

    render() {
      return <WrappedComponent
        loaded = { this.state.loaded }
        error = { this.state.error }
        isAuthenticated = { this.state.isAuthenticated }
        user = { this.state.user }
        login = { () => this.login() }
        logout = { () => this.logout() }
        getAccessToken = { (scopes) => this.getAccessToken(scopes)}
        setError = { (message, debug) => this.setErrorMessage(message, debug)}
        axios = {this.axios}
        {...this.props} {...this.state} />;
    }

    async login() {
      try {
        // Login via popup
        await this.publicClientApplication.loginPopup(
            {
              scopes: config.scopes,
              prompt: "select_account"
          });

        // After login, get the user's profile
        await this.getUserProfile();
      }
      catch(err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });
      }
    }

    logout() {
      this.publicClientApplication.logout();
    }

    async getAccessToken(scopes) {
      try {
        const accounts = this.publicClientApplication
          .getAllAccounts();

        if (accounts.length <= 0) throw new Error('login_required');
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await this.publicClientApplication
            .acquireTokenSilent({
              scopes: scopes,
              account: accounts[0]
            });

        return silentResult.accessToken;
      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err)) {
          var interactiveResult = await this.publicClientApplication
              .acquireTokenPopup({
                scopes: scopes
              });

          return interactiveResult.accessToken;
        } else {
          throw err;
        }
      }
    }

    async getUserProfile() {
      try {
        var accessToken = await this.getAccessToken(config.scopes);

        if (accessToken) {
            // Get the user's profile from Graph
            var user = await getUserDetails(accessToken);
            this.setState({
                isAuthenticated: true,
                user: {
                displayName: user.displayName,
                email: user.mail || user.userPrincipalName,
                timeZone: user.mailboxSettings.timeZone,
                timeFormat: user.mailboxSettings.timeFormat
                },
                error: null,
                loaded: true
            });
        }
      }
      catch(err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err),
          loaded: true
        });
      }
    }

    setErrorMessage(message, debug) {
      this.setState({
        error: {message: message, debug: debug}
      });
    }

    normalizeError(error) {
      var normalizedError = {};
      if (typeof(error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: error };
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error)
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error){
      if (!error.message || error.message.length <= 0) {
        return false;
      }

      return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
      );
    }
  }
}

