import dataActionTypes from './data.actionTypes';
import axios from 'axios';

export const getReps = (verify) => {
    return async (dispatch) => {
        try {
            //dispatch start message
            dispatch({ type: dataActionTypes.RETRIEVE_REPS_START });

            //pass req to api 
            axios.get('/api/salesreps', verify)
            .then((res) => {
                const reps = res.data.map(({name}) => {
                    return name;
                });
                dispatch({
                    type: dataActionTypes.RETRIEVE_REPS_SUCCESS,
                    payload: reps
                });
            })
            .catch(err => { throw err; });

        } catch (err) {
            dispatch({ 
                type: dataActionTypes.RETRIEVE_REPS_FAILURE,
                payload: err.message
            });
        }
    }
}

export const getAdvertisers = (verify) => {
    return async (dispatch) => {
        try {
            //dispatch start message
            dispatch({ type: dataActionTypes.RETRIEVE_ADVERTISERS_START });

            //pass req to api 
            axios.get('/api/advertisers', verify)
            .then((res) => {
                const advertisers = res.data.map(({_id, company_name, address}) => {
                    return {id: _id, name: company_name, address: address};
                });
                dispatch({
                    type: dataActionTypes.RETRIEVE_ADVERTISERS_SUCCESS,
                    payload: advertisers
                });
            })
            .catch(err => { throw err; });

        } catch (err) {
            dispatch({ 
                type: dataActionTypes.RETRIEVE_ADVERTISERS_FAILURE,
                payload: err.message
            });
        }
    }
}

export const getSizes = (verify) => {
    return async (dispatch) => {
        try {
            //dispatch start message
            dispatch({ type: dataActionTypes.RETRIEVE_SIZES_START });

            //pass req to api 
            axios.get('/api/sizes', verify)
            .then((res) => {
                const sizes = {web: [], print: [], other: []};
                res.data.forEach(({size, kind}) => {
                    if(kind === "web"){
                        sizes.web.push(size);
                    }else if(kind ==="print"){
                        sizes.print.push(size);
                    }else{
                        sizes.other.push(size);
                    }
                });
                dispatch({
                    type: dataActionTypes.RETRIEVE_SIZES_SUCCESS,
                    payload: sizes
                });
            })
            .catch(err => { throw err; });

        } catch (err) {
            dispatch({ 
                type: dataActionTypes.RETRIEVE_SIZES_FAILURE,
                payload: err.message
            });
        }
    }
}

export const refreshData = (verify) => {
    return async (dispatch) => {
        dispatch(getReps(verify));
        dispatch(getAdvertisers(verify));
        dispatch(getSizes(verify));
    }
}