import React from 'react';
import ShowNotes from './../notes/ShowNotes.component';
import { Button, ListGroupItem, ListGroup, Nav, NavLink, NavItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import "./advertiser.styles.scss";

const Advertiser = ({ advertiserData, address, addNote, sales, history, updateAdvertiser, id }) => {

    const {company_name, account_number, contact_person, phone, email, notes} = advertiserData;

    const goToSale = id => {
        history.push(`/app/sales/manage/${id}`);
    }

    return(
        <div className="single-advertiser">
            <div className="advertiser-actions">
                <Nav>
                    <NavItem>
                        <NavLink onClick={() => addNote()}>New Note</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => updateAdvertiser()}>Update Advertiser</NavLink>
                    </NavItem>
                </Nav>
            </div>
            <hr/>

            <div className="single-advertiser-title">
                <h6 style={{padding: "0"}}>Showing Advertiser</h6>
                <h3>{company_name}</h3>
            </div>

            <div className="advertiser-info">

                <div className="basic-advertiser-info">
                    <h6>Advertiser Details</h6>
                    <p>Account Number: <b>{account_number}</b></p>
                </div>

                <div className="basic-advertiser-info">
                    <h6>Contact Information</h6>
                    <p>Contact Person: <b>{contact_person}</b></p>
                    <p>Phone: <b>{phone}</b></p>
                    <p>Email: <b>{email}</b></p>
                </div>

                <div className="basic-advertiser-info">
                    <h6>Address</h6>
                    <span style={{textAlign: 'center'}}>
                        <p><b>{address.address1}</b></p>
                        <p><b>{address.address2}</b></p>
                        <p><b>{address.city}, {address.state} {address.zip}</b></p>
                        <p><b>{address.country}</b></p>
                    </span>
                </div>

            </div>

            <div className="advertiser-content">

                <div className="advertiser-content-left">
                    <h6>Notes</h6>
                    <ShowNotes notes={notes}/>
                    <Button className="add-note" size="sm" color="primary" onClick={() => addNote()}>+</Button>
                </div>

                <div className="advertiser-content-right">
                    <h6>All sales for this advertiser</h6>
                    <ListGroup className="sales-list">
                    { sales.length > 0 ? 
                        sales.map(({name, added, id}) => {
                            return(
                                <ListGroupItem
                                    tag="button"
                                    onClick={() => goToSale(id)}
                                    key={id}
                                >
                                    <p style={{marginBottom: "0"}}>{name}</p>
                                    <h6 style={{paddingBottom: "0"}}>Created: {added.toLocaleDateString("en-US")}</h6>
                                </ListGroupItem>
                            );
                        })
                    : null   
                    }
                    </ListGroup>
                    <Button className="add-note" size="sm" onClick={() => history.push(`/app/sales/add/organization/${id}`)}>+</Button>
                </div>

            </div>

        </div>
    );
}

export default withRouter(Advertiser);

    